import React from 'react';

// Images

// Style
import './index.scss';

export function Callout({header, image, imageHeight, imageWidth, body, className}) {
  return (
    <div className={`callout${body ? '' : ' callout-title' }`} style={{'alignItems': image ? 'center' : 'unset'}}>
      {header && <h3>{header}</h3>}
      {image && <img src={image} className='calloutImage' height={imageHeight} width={imageWidth} alt='callout'/>} 
      {body && <h2>{body}</h2>}
    </div>
  );
}