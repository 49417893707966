import React, { forwardRef } from 'react';
import { NewsletterSignup } from 'components/NewsletterSignup';
import ReactPlayer from 'react-player'

// Video
import waves from 'img/waves.mp4'

// Style
import './index.scss'

export const Footer = forwardRef((props, ref) => {
  return (
      <div className="footer" ref={ref} >
        <NewsletterSignup />
        <div className='footer-video-container'>
          <ReactPlayer className='footer-video' url={waves} playing loop muted playsinline height='auto' width='100vw' />  
        </div>
      </div>
  );
})