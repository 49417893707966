import MuxPlayer from '@mux/mux-player-react';


// A single block to account for all custom components
export const MuxVideo = ({ value }) => {
  console.log(value)
      return (
        <MuxPlayer
      playbackId={value.playbackId}
      metadata={{video_title: value.title}}
      streamType="on-demand"
    />
    )}