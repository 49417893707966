import React, { useRef } from 'react';
import ReactPlayer from 'react-player'

// Images
import headsetPNG from 'img/galea-beta.png'

// Video
import waves from 'img/waves.mp4'

// Style
import './index.scss';

export function Hero({homeRef}) {
  const containerRef = useRef(null);
  
  

  return (
    <section ref={containerRef} className={`hero full-height`}>
      <div className='hero-container'>
        <img className='static-hero-image' src={headsetPNG} alt="headset"></img>
        <h1>The World's Most Advanced&nbsp;Biosensing &nbsp;Headset&nbsp;</h1>
      </div>
      <div className='hero-video-container'>
        <ReactPlayer className='hero-video' url={waves} playing loop muted playsinline height='100vh' width='auto' />  
      </div>
    </section>
  );
}