import React from 'react'

// Style
import './index.scss'

// A single block to account for all custom components
export const Button = ({ value }) => {
  return (
    <a className='button block-button' href={value.url} target={value.blank ? '_blank' : '_self'} rel='noreferrer'>{value.text}</a>
  )
}