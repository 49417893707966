import React from 'react'

// Components

// Style
import './index.scss'


// A single block to account for all custom components
export const Youtube = ({ value }) => {
  var result = /[^/]*$/.exec(value.url)[0];
  return (
    <>
    <iframe src={value.url} title="YouTube video player" className="blockYoutube" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    {value.caption && <p className='sanity-caption'>{value.caption}</p>}
    </>
  )
}