import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useKey } from 'react-use';

// Images
import logo from 'img/open-logo.svg'
import arrow from 'img/arrow.svg'

// Style
import './index.scss'

// a basic form
const SubscribeForm = ({ status, onValidated }) => {
  let email;
  // Will move this to .env before prod
  const siteKey = '6LfSDdoZAAAAAJKRR-r0FQmbn0TXm-k6Fl1FhZT5',
        recaptchaRef = useRef(null);

  // If it's an email, we execute the recaptcha
  const validate = () => {
    recaptchaRef.current.execute()
  };

  // If the recaptcha passes, we submit the query
  const onChange = () => {
    onValidated({
      EMAIL: email.value
    })
    recaptchaRef.current.reset()
  }

  useKey('Enter', () => validate());
    
  return (
    <React.Fragment>
      <div className="contact-form">
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={siteKey}
          onChange={onChange}
        />
        <input type="email" name="EMAIL" ref={node => (email = node)} placeholder="your email" className="required email input" id="mce-EMAIL" />
        {/* This is Mailchimp's sneaky invisible bot-catching field */}
        <div style={{height: 0, width: 0}} aria-hidden="true"><input style={{height: 0, width: 0}} type="text" name="b_b37d3a1190672c5feee20ca73_b2cc9ace35" tabIndex="-1" defaultValue="" /></div>
        <button className='submit-button' type="submit" name="subscribe" onClick={validate}>SUBSCRIBE <img src={arrow} className='subscribe-arrow' alt='arrow' width='15' height='15'/></button>
        {status === "sending" && <div className='subscribe-message sending'>Sending...</div>}
        {status === "error" && (
          <div
            className='subscribe-message error'
          >
            Error
          </div>
        )}
        {status === "success" && (
          <React.Fragment>
            <div
              className='subscribe-message success'
            >
              Thank you for subscribing!
            </div>
            <img height="1" width="1" style={{display: "none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=1234937&conversionId=3821817&fmt=gif" /> 
          </React.Fragment>
        )}
      </div>
      <p className='recaptcha-disclaimer'>This site is protected by reCAPTCHA and the <span>Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span></p>
      <a href='https://openbci.com/' target="_blank" rel="noreferrer" className='footer-logo-container'><img id='headerLogo' className="footer-logo" alt='footer-logo' src={ logo } /></a>
      <a href='https://openbci.com/' target="_blank" rel="noreferrer" className='copyright'>©2024 OpenBCI, All rights reserved. </a>
    </React.Fragment>
  );
}

export function NewsletterSignup() {
  const [status, setStatus] = useState('');

  const submitSubscription = (formData) => {
    setStatus('sending');

    fetch("https://a.klaviyo.com/client/subscriptions/?company_id=R4PLHb", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        revision: '2024-02-15'
      },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: 'Galea.co',
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: formData.EMAIL,
                }
              }
            }
          },
          relationships: {list: {data: {type: 'list', id: 'UNtyn3'}}} // https://www.klaviyo.com/list/UNtyn3
        }
      })
    })
    .then(() => {
      setStatus('success');
    }).catch(() => {
      setStatus('error');
    });
  }

  return (
    <div className='contact' id="contact">
      <h3>Sign up to stay <span>up-to-date on Galea™</span></h3>
      <SubscribeForm
        status={status}
        onValidated={formData => submitSubscription(formData)}
      />
    </div>
  );
}
