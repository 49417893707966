import React, { useMemo, useEffect, useRef, forwardRef } from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Components
import { Callout } from 'components/Callout'

// Images
import electrode from 'img/electrode.gif'

// Style
import './index.scss';

export const Features = forwardRef(({homeRef, loaded}, ref) => {
  const header = <>Galea™ features</>,
        body = <>Galea beta units come fully-integrated into the world-class <a className="varjo-link" href='https://varjo.com/products/' target="_blank" rel="noreferrer" >Varjo&nbsp;headsets</a> and include a cutting-edge multimodal sensor network based on nearly a decade of OpenBCI R&D.</>,
				electrodeBody = <>Galea's conductive polymer active electrodes are the centerpiece of the world's most comfortable dry EEG system.</>,
				electrodeHeight='190px',
				electrodeWidth='214px'
				
        // call the timeline using a memo
  var tl = useMemo(() => gsap.timeline({}), [])
  const galeaExploded = useRef(null);

  useEffect(() => {
    tl.to('#fourth-front', {
      ease: 'power1.out',
      duration: 1.5,
      x: 300
    }, 0)
      .to('#fourth', {
      ease: 'power1.out',
      duration: 1.5,
      x: 300
    }, 0)
      .to('#third-front', {
      ease: 'power1.out',
      duration: 1.5,
      x: 100
    }, 0)
      .to('#third', {
      ease: 'power1.out',
      duration: 1.5,
      x: 100
    }, 0)
      .to('#second-front', {
      ease: 'power1.out',
      duration: 1.5,
      x: -100
    }, 0)
      .to('#second', {
      ease: 'power1.out',
      duration: 1.5,
      x: -100
    }, 0)
      .to('#first', {
      ease: 'power1.out',
      duration: 1.5,
      x: -300
    }, 0)
      .to('#second-be', {
      ease: 'power1.out',
      duration: 1.5,
      x: -300
    }, 0)
      .to('#electrodes', {
      ease: 'power1.out',
      duration: 1.5,
      x: -100
    }, 0)
    ScrollTrigger.create({
      scroller:'body',
      trigger: ".galea-exploded",
      animation: tl,
      start: 'bottom bottom',
			scrub: false,
      end:"top top",
      toggleActions:"play play none reverse",
			pinnedContainer: homeRef.current
    })
  }, [tl, homeRef]);

  return (
    <section className='features' ref={ref}>
      <Callout
        header={header}
        body={body}
      />
      <svg viewBox="0 0 1452.6 541.2" style={{enableBackground:"new 0 0 1452.6 521.6"}} className='galea-exploded' ref={galeaExploded} xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
				<g id="fourth">
					<path className="st0" d="M1488.8,385.7"/>
					<g>
						<g>
							<path className="st1" d="M925,80.4c-10.8-14.7-26.8-25-56.8-36.6c-35.3-13.7-62.9-20.7-77.7-24.4l-2.8-0.7
								c-51-13-88.7-19.2-111.9-18.4c-7.5,0.2-15.1,0.8-21.3,1.7c-31,4.2-63.9,28-88.2,63.7c-25.5,37.5-38.8,84-36.6,127.7
								c0.2,4.1,0.5,8.1,0.8,12.1c0,0.5,0,1,0,1.6h0.1c2.5,27.7,8.5,52.4,17.7,73.5c11.8,27.1,28.8,47.7,50.5,61.4
								c12.2,7.7,22.3,12.3,33.7,16.8c23.1,9.2,37.9,11.7,87.2,19.7c9,1.5,19.2,3.1,30.8,5.1c27.2,4.5,36.1,5.6,40.7,2.3l0.2-0.1
								l-0.1-2.4c-1.1-34.8,4.2-60.3,4.3-60.7c0.4-2,1.4-6.4,1.9-8.4l1.7-6.9c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0.1
								c-2.1-2.7-4.6-4.9-7.3-6.7c-5.5-3.6-14.3-5.2-15.3-5.4c-17.8-3.1-29.4-4.8-37.9-6c-5.9-0.9-10.6-1.5-14.9-2.3
								c-5.4-1-10.2-1.8-14.4-2.6c-13-2.2-21.6-3.7-31.7-8c-23.5-9.9-44.3-39.5-47.8-46.6c-13.2-26.9-11.4-52.4-8.9-65.8
								c9.2-30.9,34.5-59.3,61.6-67.1c7.2-2.1,14.2-1.1,29.7,1c3.5,0.5,7.5,1,12,1.6c42.7,5.4,90.2,18.6,99.1,21.7
								c1.6,0.5,3.2,1.1,4.9,1.7c18.1,6.2,42.7,14.6,67.5,28.6c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0.1,0,0.1-0.1l1.8-1.3
								c2.6-1.8,5.3-3.6,8-5.2c0.8-0.5,1.8-1,2.7-1.5c5.3-2.9,11.3-6.3,15.6-15.1c1.6-3.3,2.9-7.8,3-14.1v94.3c3-8.1,3.7-16,4.1-24.3
								v-0.3c0.2-3.4,0.4-7.4,0.6-11.7c0.6-12.7,1.4-28.4,3.1-47.1C941.3,103.9,927.8,84.1,925,80.4z"/>
						</g>
						<path className="st0" d="M799.1,306.9c-2.1-2.7-4.6-4.9-7.3-6.7c-5.5-3.6-14.3-5.2-15.3-5.4c-17.8-3.1-29.4-4.8-37.9-6
							c-5.9-0.9-10.6-1.5-14.9-2.3c-5.4-1-10.2-1.8-14.4-2.6c-13-2.2-21.6-3.7-31.7-8c-23.5-9.9-44.3-39.5-47.8-46.6
							c-13.2-26.9-11.4-52.4-8.9-65.8c9.2-30.9,34.5-59.3,61.6-67.1c7.2-2.1,14.2-1.1,29.7,1c3.5,0.5,7.5,1,12,1.6
							c42.7,5.4,90.2,18.6,99.1,21.7c1.6,0.5,3.2,1.1,4.9,1.7c18.1,6.2,42.9,14.6,67.7,28.7l1.8-1.3c-25.3-14.5-50.5-23.1-68.9-29.3
							c-1.7-0.6-3.3-1.1-4.9-1.7c-9-3.1-56.7-16.4-99.5-21.8c-4.5-0.6-8.5-1.1-12-1.6c-15.7-2.1-22.9-3.1-30.5-0.9
							c-27.5,7.9-53.1,36.4-62.7,67.6h-0.1c-0.1,0.3-0.1,0.7-0.2,1c-1.6,5.3-2.7,10.6-3.3,15.9c-5.1,46.5,25.6,88.9,58.2,103
							c9.7,4.2,31.5,7.6,74.9,14.3c1.6,0.2,3.2,0.5,4.7,0.7c22.3,3.4,37,5.6,42.8,19.5l0.2,0.8l0.8-3.4c-6.7-13.3-22.3-15.6-43.5-18.9
							c-1.5-0.2-3.1-0.5-4.7-0.7c-43.2-6.7-64.9-10.1-74.4-14.2c-30.4-13.2-59.1-51.5-57.5-94.4c0.3,12.8,3,28.3,10.9,44.4
							c3.5,7.2,24.7,37.4,48.8,47.5c10.3,4.4,19,5.9,32.2,8.1c4.2,0.7,9,1.5,14.4,2.6c4.3,0.8,9,1.5,14.9,2.4c8.5,1.2,20.1,2.9,37.8,6
							c0.9,0.2,9.4,1.7,14.5,5.1c3,1.9,5.6,4.5,7.8,7.6L799.1,306.9z"/>
						<path className="st0" d="M927.4,87.9c1.4,2.6,3,5.7,4.3,9.4c3.5,9.7,4,18.7,1.6,27c-1.3,4.4-3.4,8.2-5.9,11.4v3.2
							c2-2.3,3.8-4.8,5.3-7.7c-1.3,15.9-2,29.4-2.5,40.6c-0.2,4.4-0.4,8.3-0.6,11.7v0.3c-0.4,6.2-0.7,11.9-2.1,17.7v6.7
							c3.2-8.3,3.6-15.8,4.1-24.3v-0.3c0.2-3.4,0.4-7.4,0.6-11.7c0.6-12.7,1.4-28.4,3.1-47.1c4.9-17-3.2-33.4-7.9-40.8L927.4,87.9z"/>
						<path className="st0" d="M927.6,100.2c-9.3-34.3-64.1-51.9-104.2-64.8l-2.1-0.7c-15.6-5-28.5-8.4-55.3-14.5
							C687.8,2.3,659.9,5.8,642.9,11.6c-17.5,5.9-28.2,14.3-34.7,19.3c-0.7,0.5-1.3,1-1.9,1.5c-4.2,3.2-15.1,13.4-29.5,31.3
							c-4.4,5.4-39.1,50.7-45.3,118.6c0.3-40,13.3-81.7,36.5-115.7c23.9-35.3,56.4-58.8,86.8-62.9c6.1-0.8,13.6-1.4,21.1-1.7
							c23.1-0.8,60.5,5.4,111.3,18.4l2.8,0.7c14.8,3.7,42.2,10.7,77.5,24.4c29.7,11.5,45.4,21.6,55.9,35.9c0.7,0.9,2.2,3.1,4,6.3v-4
							c-1-1.6-1.9-2.8-2.4-3.5c-10.8-14.7-26.8-25-56.8-36.6c-35.3-13.7-62.9-20.7-77.7-24.4l-2.8-0.7c-51-13-88.7-19.2-111.9-18.4
							c-7.5,0.2-15.1,0.8-21.3,1.7c-31,4.2-63.9,28-88.2,63.7c-25.5,37.5-38.8,84-36.6,127.7c0.2,4.1,0.5,8.1,0.8,12.1c0,0.5,0,1,0,1.6
							h0.1c2.5,27.7,8.5,52.4,17.7,73.5c11.8,27.1,28.8,47.7,50.5,61.4c12.2,7.7,22.3,12.3,33.7,16.8c23.1,9.2,37.9,11.7,87.2,19.7
							c9,1.5,19.2,3.1,30.8,5.1c27.2,4.5,36.1,5.6,40.7,2.3l-0.1-2.5c-3.5,3.6-9.7,3.3-40.3-1.8c-11.6-1.9-21.8-3.6-30.8-5.1
							c-49.1-8-63.9-10.5-86.8-19.6c-11.3-4.5-21.2-9-33.3-16.7c-21.3-13.4-38.1-33.8-49.7-60.5c-9.3-21.3-15.2-46.3-17.7-74.4
							c-0.6-79.7,41.1-134.2,45.8-140.1c14.3-17.8,25-27.9,29.1-31c0.6-0.4,1.2-0.9,1.9-1.5c6.3-4.9,16.9-13.2,34.1-19
							c16.7-5.7,44.2-9.1,122,8.7c26.7,6.1,39.6,9.5,55.2,14.5l2.1,0.7c39.6,12.7,93.9,30.2,102.9,63.4c1.3,4.8,1.8,9,1.8,12.7v12
							C929.4,119.7,930.6,111.3,927.6,100.2"/>
					</g>
				</g>
				<g id="third">
					<g>
						<g>
							<path className="st1" d="M857.4,340.5c0,0.4,0,0.8,0,1.2C857.4,341.3,857.4,340.9,857.4,340.5z"/>
							<path className="st1" d="M927.4,167.5c-0.3-13.5-0.6-31.7,0-49.3c0-0.6,0.1-1.1,0.1-1.6c0-0.6,0-1.2,0.1-1.8h-0.1
								c-0.1-6.3-1.6-11.1-2.2-13.3c-7.7-26-35.4-38-70.4-53.3c-23.3-10.1-48-17.2-61.2-21c-3.4-1-10.5-3-19.8-5.3
								C738.8,13,711.1,6,677.9,6c-13.1,0-24.4-0.1-38.4,3.1c-49.7,11.1-81,49.5-95.2,71.3c-32.7,50.2-28.9,103.2-27.5,123.1
								c2.1,29.2,10.1,59.7,22.6,85.8c13.9,29.1,32.8,52.1,54.6,66.5c23.2,15.3,51.6,24,51.9,24.1c6.9,2.1,9.8,2.6,23.8,4.9
								c5.7,0.9,13.6,2.2,24.4,4.1c5.7,1,10.8,1.9,15.4,2.7c30.6,5.3,39.4,6.8,46,3.5c0,0.1,0,0.1,0,0.2c0-0.1,0-0.1,0-0.2l-0.2-2.2
								c-0.3-3.9-0.5-7.4-0.6-10.5c-0.7-20.2,1.8-35.1,4.4-50.8c0.3-1.9,1.2-6.7,2.7-13.7l0.5-2.3c0.9-4,2-8.7,3.3-13.8l0.6-2.1
								c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-4-2.4-11.3-4.1-25.1-7c-10.3-2.2-19.4-3.6-36-6.1l-0.8-0.1
								c-25.6-4-28.9-4.5-34.7-7.1c-4-1.8-14.3-7-23.1-16.8c-18.5-20.5-18.7-46.6-19-69.7c-0.2-20-0.5-40.6,13.2-60
								c3.2-4.6,9.2-13,19.8-19c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.1,0.5-0.3,0.8-0.4c17.3-8.4,38.8-3.1,80.1,7.2l8.1,2
								c11.7,2.9,30.2,8.1,52.3,16.7c15,5.8,29.8,12.5,44.1,19.8l1.5-1.5c2.9-2.8,5.8-5.6,8.8-8.4c4.5-4.1,8.7-7.4,12.3-10.3
								c9.3-7.5,15.5-12.4,17.1-21.1c0.3-1.6,0.4-3.1,0.4-4.5h-1l-0.3,134.4h1c2.5-2.5,5.4-4.9,8.4-6.9c1.2-0.8,2.6-1.6,4-2.5
								c7.2-4.3,16.1-9.7,21.9-21c4.9-9.5,7.2-22.4,7.1-36.8C927.3,176.2,927.5,172,927.4,167.5z"/>
						</g>
						<g>
							<path className="st0" d="M927.4,167.5c-0.3-13.5-0.6-31.7,0-49.3c0-0.6,0.1-1.1,0.1-1.6c0-0.6,0-1.2,0.1-1.8h-0.1
								c-0.1-6.3-1.6-11.1-2.2-13.3c-7.7-26-35.4-38-70.4-53.3c-23.3-10.1-48-17.2-61.2-21c-3.4-1-10.5-3-19.8-5.3
								C738.8,13,711.1,6,677.9,6c-13.1,0-24.4-0.1-38.4,3.1c-49.7,11.1-81,49.5-95.2,71.3c-32.7,50.2-28.9,103.2-27.5,123.1
								c2.1,29.2,10.1,59.7,22.6,85.8c13.9,29.1,32.8,52.1,54.6,66.5c23.2,15.3,51.6,24,51.9,24.1c6.9,2.1,9.8,2.6,23.8,4.9
								c5.7,0.9,13.6,2.2,24.4,4.1c5.7,1,10.8,1.9,15.4,2.7c30.6,5.3,39.4,6.8,46,3.5l-0.2-2.2c-6.1,3.6-14.2,2.2-45.5-3.2
								c-4.6-0.8-9.7-1.7-15.4-2.7c-10.8-1.8-18.6-3.1-24.4-4.1c-13.8-2.3-16.7-2.8-23.5-4.8c-0.3-0.1-28.4-8.7-51.4-23.9
								c-20.5-13.6-38.4-34.8-51.9-61.7h0.1C517.6,223.7,521,162.4,532.7,128c8.7-25.6,19.2-43.5,36.1-61.6c11.8-12.6,22.2-21.3,36.1-30
								c21.9-13.7,43.6-17.3,62.8-17.6l0.4,0.4l0.4-0.4c10.2-0.1,19.6,0.7,28,1.4c1.8,0.1,3.5,0.3,5.1,0.4c18.5,1.4,39.2,7.5,80.5,19.7
								c20.2,5.9,42.4,14.8,62.3,24.9c26.5,13.4,34.6,23.1,39,34.3c1.2,3.1,2.9,7.5,3,12.8v10.2c0.7-1.6,1.2-3.3,1.5-5.1
								c1.5-7.9-1.1-14.6-2.7-18.5c-2.9-7.6-7.5-14.4-17.6-22l25.6-7.9c15.1,8.8,26,18.9,30.2,33.3c0.7,2.2,2.3,7.7,2.1,14.5
								c0,0.5,0,1-0.1,1.5c-0.1,2.5-0.5,5.2-1.3,8c-3.7,13.1-14,19.7-27.1,28.1c-0.7,0.4-1.4,0.9-2.1,1.3l-8.5-1.6v2l6,1.2
								c-2.1,1.3-3.9,2.5-6,4v2.4c4.2-2.9,7.9-5.3,11.6-7.7c12.5-8,22.6-14.5,27-26.5c-0.2,13.9,0,27.5,0.2,38.2
								c0.1,4.5,0.2,8.4,0.2,11.6c0.1,14.1-2.4,27-7.1,36.2c-5.5,10.8-14.2,16-21.1,20.2c-1.5,0.9-2.8,1.7-4.1,2.6
								c-2.5,1.6-4.8,3.5-7,5.5v2.8c2.5-2.4,5.2-4.6,8.1-6.6c1.2-0.8,2.6-1.6,4-2.5c7.2-4.3,16.3-9.9,22.1-21.2
								c4.9-9.5,7.3-22.5,7.2-36.9C927.6,175.9,927.5,172,927.4,167.5z M667.4,16.8c-19.3,0.4-41.7,4.1-63.7,17.9
								c-14,8.8-24.6,17.5-36.5,30.3c-17.1,18.3-27.7,36.4-36.5,62.3c-8.3,24.4-11,53.1-7.9,85.5c1.9,20.4,6.3,41.9,12.4,62.2
								c-9-22.6-14.8-47.5-16.5-71.6c-1.5-21.6-5.2-72.2,27.2-121.9c14.1-21.6,45-59.5,94-70.5c13-2.9,23.7-3.1,35.7-3.1L667.4,16.8z
								M723.1,21.9c-8-1.8-14.9-2.9-21.6-3.4c-1.6-0.1-3.4-0.3-5.1-0.4c-7.6-0.6-16.6-1.4-26.3-1.3l8.1-8.7c16.3,0.1,31.4,1.8,46.7,4.7
								L723.1,21.9z M865.5,75.1c-5.2-3.7-11.9-7.6-20.3-11.9c-20.1-10.1-42.3-19-62.7-25c-25.5-7.5-43.2-12.7-57.4-15.9l1.8-9.2
								c14.7,2.8,29.7,6.6,46.4,10.8c9.3,2.3,16.4,4.3,19.8,5.3c13.2,3.8,37.8,10.8,61,20.9c13.6,5.9,26.1,11.4,36.7,17.3L865.5,75.1z"
								/>
							<g>
								<g>
									<path className="st0" d="M802.7,136.4c-22.2-8.6-40.9-13.9-52.6-16.8l-8.1-2c-37.4-9.3-59.8-14.9-77.3-8.8l-0.1-0.2
										c-1.4,0.6-2.8,1.3-4.2,2c-0.2,0.1-0.4,0.2-0.7,0.3c-12.6,6.4-27.7,17.9-36.9,38c-8.8,19.2-9.6,37.7-7.9,63
										c1.3,19,3.7,39,17.5,58c2.6,3.5,8.6,11.7,19.1,18.5c0.4,0.3,2.6,1.6,5.1,2.8c13.2,6.6,30.4,8.6,56.5,11.8l6.1,0.7
										c8.1,1,16,2.4,23.5,4.3c5.2,1.3,10.4,2.9,15.5,6.7c1.2,0.9,2.4,2,3.5,3.1l0.5-2.3c-0.9-0.8-1.8-1.6-2.8-2.4
										c-4.8-3.7-9.6-5.3-14.5-6.6l9.4-9.3c5.6,1.4,9.3,2.8,11.2,4.5l0.6-2.1c-3.7-2.8-11.1-4.4-26-7.6c-10.3-2.2-19.4-3.6-36-6.1
										l-0.8-0.1c-25.6-4-28.9-4.5-34.7-7.1c-4-1.8-14.3-7-23.1-16.8c-18.5-20.5-18.7-46.6-19-69.7c-0.2-20-0.5-40.6,13.2-60
										c3.2-4.6,9.2-13,19.8-19c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.1,0.5-0.3,0.8-0.4c17.3-8.4,38.8-3.1,80.1,7.2l8.1,2
										c11.7,2.9,30.2,8.1,52.3,16.7c15,5.8,29.8,12.5,44.1,19.8l1.5-1.5C833,149.1,817.9,142.3,802.7,136.4z M702.9,287.8l0.8,0.1
										c16.5,2.5,25.6,4,35.9,6.1c4.8,1,8.9,1.9,12.4,2.7l-9.3,9.2c-7.4-1.9-15.2-3.3-23.2-4.3l-6.1-0.7c-25-3-41.8-5-54.5-11l9.6-9.2
										C674,283.3,678.5,284,702.9,287.8z M638,131.2c-14,19.9-13.8,40.9-13.6,61.1c0.3,23.4,0.6,49.9,19.5,71
										c8.2,9.1,17.7,14.4,22.4,16.6l-9.6,9.2c-2.1-1.1-3.9-2.2-4.3-2.4c-10.2-6.5-15.8-14.3-18.5-18c-13.5-18.5-15.9-38.3-17.1-57
										c-1.7-25-0.9-43.2,7.7-62c4.7-10.3,11-18.2,17.7-24.4C640.5,127.6,639.1,129.6,638,131.2z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g id="second">
					<g>
						<g>
							<path className="st1" d="M885.9,111.9c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2-0.1,0.3-0.1c0-0.3-0.1-0.6-0.1-0.8l0,0l0,0
								c-0.1-0.5-0.2-1-0.3-1.4v-0.1c-1.8-9-6.6-16.8-15.4-25.2c-7.7-7.4-15.9-13-28.4-18.8v-0.2l-0.2,0.1c-9-4.1-20.2-8.3-35-13.2
								c-8.8-2.9-18-6-29-9.2c-2.1-0.6-9.7-2.8-18.5-5.1c-13.2-3.4-48.2-12.6-89.2-15.6c-7.4-0.5-12.5-0.7-18.9-0.1
								c-0.2,0-18.8,1.8-36.9,10.8c-27.2,13.6-45.6,40.4-52.3,51.3c-14.4,23.6-30.4,66.3-32.2,115.5c-1.4,36.9,5,89.5,43.1,131.3
								c27.7,30.3,59.6,40.2,60.9,40.6c7.3,2.4,10.6,3,19.5,4.4c6.2,1,15.5,2.5,31,5.5c9.1,1.7,18.3,3.2,27.3,4.6
								c5.3,0.8,10.7,1.7,16,2.6l1.5,0.2c15.7,2.7,20.5,3.5,25.2,0.2c1.2-0.8,2.2-1.8,3.1-3l-0.1-3c-0.6-15.2-0.1-32.6,2.5-51.9l0.5-3.4
								c0.1-0.9,0.3-1.8,0.4-2.7l0.5-3.1c0-0.2,0.1-0.4,0.1-0.7c-1-1.5-2.1-2.9-3.4-4.1c-4.2-3.8-9.9-5.7-11.8-6.2
								c-23.3-5.5-63.1-10.8-63.5-10.9c-18-2.7-26.5-4.4-32.2-7.6c-19.8-11.2-27.6-32.9-33.3-52.3c-6.3-21.6-7.7-44.3-4-67.7
								c5.5-26.2,19.2-60.7,45.9-68.5c4.7-1.4,14.8,0,34.9,2.6c26.1,3.5,51.9,8.8,78.9,16.2c9.7,2.7,19.8,5.4,32.9,10
								c16.2,5.7,32.1,12.4,47.3,20.1l0,0c0.1,0,0.2,0.1,0.3,0.1l1.2-1.1c0.4-0.3,0.8-0.7,1.1-1c14.3-13,21.5-19.5,22.8-30.4
								c0.1-1,0.2-2,0.2-3c0-1.9-0.2-3.8-0.5-5.5L885.9,111.9z"/>
						</g>
						<g>
							<g>
								<g>
									<path className="st0" d="M599.4,49.4c-9.7,7.8-17.3,16.3-26.6,29.5c-7.2,10.4-27,38.7-38.1,90.3c5.7-35.2,17.7-64.8,28.8-83
										c5.5-9.1,19.4-29.4,39.7-43.5l1.8,2.4C603.1,46.5,601.2,47.9,599.4,49.4z M642.6,29.2c-2.6,0.4-5,0.9-7.2,1.4
										c-9.3,2.3-19.3,7-28.8,13.4l-1.8-2.4c3.2-2.2,6.6-4.2,10.1-5.9c17.8-8.9,36-10.6,36.2-10.6c6.2-0.6,11.3-0.4,18.5,0.1
										c2.4,0.2,4.8,0.4,7.1,0.6l-3.2,2.9C658,27.6,648.2,28.3,642.6,29.2z M786.8,51.6c-2.8-0.9-4.5-1.5-5.9-2
										c-0.9-0.3-1.7-0.6-2.6-0.9c-15.8-5.3-39.8-10.6-40-10.7c-26.6-5.4-46.8-8-61.8-9.2l3.2-2.8c36.5,3.6,66.9,11.5,79,14.7
										c8.8,2.3,16.3,4.5,18.4,5.1c11,3.2,20.1,6.3,29,9.2c13.5,4.5,24,8.4,32.5,12.1l-5.1,1.4C821.7,63.4,806.5,57.8,786.8,51.6z
										M885.9,111.9c-0.1-0.5-0.2-1.1-0.3-1.4v-0.1c-1.8-9-6.6-16.8-15.4-25.2c-7.7-7.4-15.9-13-28.4-18.8v-0.2l-0.2,0.1
										c-9-4.1-20.2-8.3-35-13.2c-8.8-2.9-18-6-29-9.2c-2.1-0.6-9.7-2.8-18.5-5.1c-13.2-3.4-48.2-12.6-89.2-15.6
										c-7.4-0.5-12.5-0.7-18.9-0.1c-0.2,0-18.8,1.8-36.9,10.8c-27.2,13.6-45.6,40.4-52.3,51.3c-14.4,23.6-30.4,66.3-32.2,115.5
										c-1.4,36.9,5,89.5,43.1,131.3c27.7,30.3,59.6,40.2,60.9,40.6c7.3,2.4,10.6,3,19.5,4.4c6.2,1,15.5,2.5,31,5.5
										c9.1,1.7,18.3,3.2,27.3,4.6c5.3,0.8,10.7,1.7,16,2.6l1.5,0.2c15.7,2.7,20.5,3.5,25.2,0.2c1.2-0.9,2.3-2,3.3-3.2l-0.2-3.3
										c-1.2,2.1-2.6,3.7-4.3,4.9c-4.1,2.8-8.2,2.1-23.7-0.5l-1.5-0.2c-5.3-0.9-10.8-1.8-16-2.6c-8.9-1.4-18.2-2.9-27.2-4.6
										c-15.5-3-24.9-4.5-31-5.5c-8.8-1.4-12.1-2-19.2-4.4c-0.3-0.1-32.5-10-60-40c-37.7-41.2-44-93.4-42.6-129.9c0-0.2,0-0.5,0-0.7
										h0.1c8.4-70.9,34.2-107.9,42.7-120c9.1-13,16.7-21.4,26.2-29.1c11.3-9.1,23.8-15.6,35.3-18.4c2.2-0.5,4.5-1,7-1.4
										c10.9-1.7,37.8-2.8,94.9,8.8c0.2,0.1,24.1,5.3,39.8,10.6c1,0.3,1.7,0.6,2.6,0.9c1.5,0.5,3.1,1.1,6,2
										c62.4,19.7,80,33.2,87.1,47.6c1.5,3,3.5,7,4.4,11.8l2-0.3c-1-5.1-3.1-9.3-4.6-12.4c-4.1-8.3-11.3-17.9-38.9-30.3l5.1-1.4
										c12,5.6,20,11,27.4,18.2c8.5,8.2,13,15.6,14.8,24.2v0.1c0.1,0.3,0.2,0.8,0.2,1.2L885.9,111.9z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="st0" d="M760.2,328.4c-6.6-10.7-20.3-13.2-34.9-15.8l-2.9-0.5c-28.8-5.2-44.4-7.5-53.7-8.9
										c-10.6-1.5-13.1-1.9-17.9-4.1c-25.4-11.6-38.6-46.2-41-81.2c1,8.2,2.6,16.2,5,24.1c5.8,19.8,13.7,41.9,34.2,53.5
										c6,3.4,14.6,5.1,32.9,7.8c0.4,0.1,40.2,5.4,63.3,10.9c1.7,0.4,7.1,2.2,10.9,5.7c1.8,1.6,3.2,3.6,4.4,5.8l0.5-3.1
										c-1-1.5-2.2-3-3.5-4.2c-4.2-3.8-9.9-5.7-11.8-6.2c-23.3-5.5-63.1-10.8-63.5-10.9c-18-2.7-26.5-4.4-32.2-7.6
										c-19.8-11.2-27.6-32.9-33.3-52.3c-6.3-21.6-7.7-44.3-4-67.7c5.5-26.2,19.2-60.7,45.9-68.5c4.7-1.4,14.8,0,34.9,2.6
										c26.1,3.5,51.9,8.8,78.9,16.2c9.7,2.7,19.8,5.4,32.9,10c16.2,5.7,32.1,12.4,47.3,20.1l1.6-1.4c-15.5-7.8-31.8-14.7-48.2-20.5
										c-13.2-4.6-23.3-7.4-33-10.1c-27.1-7.4-53-12.7-79.2-16.2c-20.4-2.7-30.6-4.1-35.8-2.6c-27.5,8-41.7,43.3-47.3,70l0,0
										c0,0.1,0,0.1,0,0.2c-1,4.7-1.7,9.1-2.2,13.1c-2.6,21.7-0.6,45.7,5.5,65.9c5.1,16.9,15.4,39,36,48.5c5,2.3,7.8,2.7,18.4,4.2
										c9.3,1.4,24.9,3.6,53.6,8.9l2.9,0.5c14.5,2.6,28.2,5,34,15.7c0.3,0.5,0.5,1,0.7,1.5L760.2,328.4z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g id="second-be">
					<path className="st2" d="M728,78.1c-2.4,0.2-4.7-0.7-6.6-2.4c-1.9-1.8-3.1-4.3-3.3-7.1c-0.5-5.7,3.2-10.6,8.1-11.1l0,0
						c5-0.4,9.4,3.9,9.9,9.5C736.6,72.8,733,77.7,728,78.1z"/>
					<path className="st2" d="M824,143.6c-2.6,0.2-5.2-3.3-5.5-7.8s1.5-8.4,4.1-8.6s5.2,3.3,5.5,7.8S826.6,143.4,824,143.6z"/>
					<path className="st2" d="M769.3,89.3c-2.4,0.2-4.7-0.7-6.6-2.4c-1.9-1.8-3.1-4.3-3.3-7.1s0.5-5.5,2.1-7.5c1.5-2.1,3.7-3.3,6.1-3.5l0,0
						c0.2,0,0.5,0,0.7,0c2.1,0,4.2,0.9,5.9,2.5c1.9,1.8,3.1,4.3,3.3,7.1C777.9,83.9,774.3,88.9,769.3,89.3z"/>
					<path className="st2" d="M747,115c-5,0.4-9.4-3.9-9.9-9.5c-0.2-2.8,0.5-5.5,2.1-7.5c1.5-2.1,3.7-3.3,6.1-3.5l0,0c0.2,0,0.5,0,0.7,0
						c2.1,0,4.2,0.9,5.9,2.5c1.9,1.8,3.1,4.3,3.3,7.1C755.6,109.6,751.9,114.6,747,115z"/>
					<path className="st2" d="M789,122.9c-1.2,2-2.8,3.2-4.7,3.3c-3.9,0.3-7.5-4-8-9.7c-0.2-2.8,0.3-5.5,1.6-7.6c1.2-2,2.8-3.2,4.7-3.3
						c1.8-0.2,3.7,0.7,5.2,2.5c1.6,1.8,2.6,4.4,2.8,7.2C790.8,118.1,790.3,120.8,789,122.9z"/>
					<path className="st2" d="M806.9,105c-1.2,2-2.8,3.2-4.7,3.3c-3.9,0.3-7.5-4-8-9.7s2.3-10.6,6.2-10.9c1.8-0.2,3.7,0.7,5.2,2.5
						c1.6,1.8,2.6,4.4,2.8,7.2C808.7,100.3,808.1,102.9,806.9,105z"/>
					<path className="st2" d="M841.8,131.3c-2.3,0.2-4.4-2.7-4.7-6.4c-0.2-1.9,0.2-3.7,0.9-5.1c0.7-1.2,1.6-2,2.7-2.1l0,0c0.1,0,0.1,0,0.2,0
						c1,0,1.9,0.6,2.8,1.6c1,1.2,1.6,2.9,1.8,4.8C845.7,127.8,844.1,131.1,841.8,131.3z"/>
					<path className="st0" d="M659.8,67.4c-1.6,0.6-3.3,1-5,1c-2.1,0-4.2-0.6-6.2-1.6c-3.9-2.1-6.6-5.9-7.2-10.1c1.8-1.4,1.8-1.4,1.8-1.4
						c0.3,4,2.6,7.6,6.3,9.6c3.4,1.8,7.1,1.8,10.3,0C659.8,64.9,660,66.3,659.8,67.4z"/>
					<path className="st2" d="M659.8,67.4c-0.5,2.9-2,5.6-4.4,7.5s-5.4,2.8-8.3,2.4c-3-0.4-5.7-1.9-7.5-4.4c-1.9-2.5-2.7-5.5-2.3-8.6
						c0.4-3,1.8-5.7,4.1-7.6c0.6,4.2,3.3,8,7.2,10.1c2,1,4.1,1.6,6.2,1.6C656.5,68.4,658.2,68,659.8,67.4z"/>
					<path className="st2" d="M712.7,335.9c1.5-2.3,3.6-3.7,6-3.9l0,0c0.2,0,0.4,0,0.6,0c2.2,0,4.3,1,6,2.9c1.9,2.1,3.2,5,3.4,8.2
						c0.3,3.2-0.4,6.3-2,8.7c-1.5,2.3-3.6,3.7-6,3.9c-2.3,0.2-4.7-0.8-6.6-2.8c-1.9-2.1-3.2-5-3.4-8.2
						C710.4,341.4,711.1,338.3,712.7,335.9z"/>
					<path className="st2" d="M598,192.8c-0.8,6.4-6.5,11-12.7,10.3c-6.2-0.8-10.6-6.6-9.8-13c0.1-0.6,0.2-1.2,0.4-1.8
						c2.5,3.4,6.5,5.5,10.9,5.5c0.1,0,0.2,0,0.3,0c4.4-0.1,8.2-2.1,10.5-5.6C598,189.7,598.2,191.2,598,192.8z"/>
					<path className="st0" d="M597.6,188.2c-2.3,3.5-6.1,5.5-10.5,5.6h-0.3c-4.4,0-8.4-2.1-10.9-5.5l0.7-2.3c2.1,3.7,6,5.9,10.2,5.8h0.3
						c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1c3.3-0.6,6-2.6,7.5-5.6L597.6,188.2z"/>
					<g>
						<path className="st2" d="M653.9,359.9c-2.5-2.9-3.8-6.4-3.6-9.6c-4.1,1.2-7.4,4.9-8,9.6c-0.4,3,0.4,6,2.2,8.2c1.7,2.4,4.2,3.9,7,4.2
							c4.8,0.6,9.3-2.4,11.1-7.1C659.5,364.6,656.3,362.7,653.9,359.9z"/>
					</g>
					<path className="st0" d="M663.1,363.5c-0.1,0.2-0.2,0.9-0.5,1.7c-3.1-0.6-6.3-2.5-8.7-5.3c-2.5-2.9-3.8-6.4-3.6-9.6l2-0.2
						c-0.3,2.8,0.8,5.9,3.1,8.6c1.5,1.9,3.4,3.3,5.4,4.1c0.1,0,0.2,0.1,0.2,0.1C661.7,363.1,662.4,363.3,663.1,363.5z"/>
					<path className="st2" d="M691,367c0.7-5.7,5.3-9.9,10.6-9.9c0.4,0,0.8,0,1.2,0.1c0.2,0,0.4,0.1,0.6,0.1c-0.9,2.9-0.8,6.1,0.3,9
						c1.3,3.3,3.7,5.9,6.9,7.4c-2,3.9-6.2,6.4-10.6,5.9c-2.8-0.3-5.3-1.8-7-4.2C691.4,372.9,690.7,370,691,367z"/>
					<path className="st0" d="M711.4,371.8L711.4,371.8l-0.8,1.9c-3.2-1.5-5.6-4.1-6.9-7.4c-1.1-2.9-1.2-6.1-0.3-9l1.8,0.5h0.1
						c-0.7,2.5-0.7,5.2,0.3,7.7C706.7,368.3,708.7,370.5,711.4,371.8z"/>
				</g>
				<g id="electrodes">
					<g>
						<g>
							
								<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="936.1618" y1="-798.6898" x2="925.127" y2="-828.6704" gradientTransform="matrix(0.7811 -0.6243 0.6243 0.7811 367.5316 1400.988)">
								<stop  offset="0" style={{stopColor:"#9F5424"}}/>
								<stop  offset="3.244472e-02" style={{stopColor:"#AB5A24"}}/>
								<stop  offset="0.125" style={{stopColor:"#CA6925"}}/>
								<stop  offset="0.2041" style={{stopColor:"#DC7326"}}/>
								<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
								<stop  offset="0.3774" style={{stopColor:"#E87F23"}}/>
								<stop  offset="0.5717" style={{stopColor:"#F5981D"}}/>
								<stop  offset="0.6086" style={{stopColor:"#F89E1B"}}/>
								<stop  offset="0.672" style={{stopColor:"#F4961D"}}/>
								<stop  offset="0.772" style={{stopColor:"#E87F24"}}/>
								<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
								<stop  offset="1" style={{stopColor:"#9F5424"}}/>
							</linearGradient>
							<path className="st3" d="M598.6,197.7c0.2-0.5,0.4-1.1,0.4-1.7l0.5-19.8c0-2-1.2-3.9-3.5-5.4c-2.1-1.4-5-2.4-8-2.6
								c-3-0.3-5.9,0.2-8.1,1.2c-2.3,1.1-3.7,2.8-3.7,4.8l-0.5,19.8l0,0c-0.1,3.9,4.9,7.5,11.4,8C592.7,202.5,597.3,200.7,598.6,197.7z"
								/>
							<path className="st4" d="M576.5,195.8c0-0.4,0.1-0.8,0.3-1.1c1.1-2.5,5.6-4.2,10.8-3.7c3,0.3,5.8,1.2,7.9,2.6
								c1.9,1.3,2.9,2.8,2.9,4.2c-0.1,3.2-5.1,5.4-11,4.9C581.4,202,576.4,198.9,576.5,195.8z"/>
						</g>
						<g>
							<g>
								<g>
									<g>
										
											<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="844.8614" y1="391.7228" x2="844.8614" y2="412.7461" gradientTransform="matrix(1 0 0 -1 0 536.9373)">
											<stop  offset="0" style={{stopColor:"#A05524"}}/>
											<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
											<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
											<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
											<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
											<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
											<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
											<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
											<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
											<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
											<stop  offset="1" style={{stopColor:"#A05524"}}/>
										</linearGradient>
										<path className="st5" d="M825.7,123.8h35.5c0,0,8.5,13.2-4,21.6l-31.4-0.2L825.7,123.8z"/>
										<g>
											<path className="st4" d="M825.7,123.8c0.4,0,0.8,0.1,1.2,0.2c2.8,1,5,5.3,5,10.4c0,3-0.7,5.7-2,7.8c-1.2,1.9-2.7,3-4.2,3
												c-3.4,0.1-6.2-4.8-6.2-10.6C819.5,128.9,822.4,123.9,825.7,123.8z"/>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<defs>
														<path id="SVGID_3_" d="M854.1,151.9c0,0,34.6-23.8,23.8-38.7c4-21.4,0-86.3,0-86.3h-111l48.5,168.8L854.1,151.9z"/>
													</defs>
													<clipPath id="SVGID_4_">
														<use xlinkHref="#SVGID_3_"  style={{overflow:"visible"}}/>
													</clipPath>
													<g className="st6">
														<g>
															<g>
																
																	<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="863.9754" y1="401.5131" x2="853.5339" y2="429.8823" gradientTransform="matrix(1 0 0 -1 0 536.9373)">
																	<stop  offset="0" style={{stopColor:"#A05524"}}/>
																	<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
																	<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
																	<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
																	<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
																	<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
																	<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
																	<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
																	<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
																	<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
																	<stop  offset="1" style={{stopColor:"#A05524"}}/>
																</linearGradient>
																<path className="st7" d="M843.6,134c0.6,0.2,1.2,0.3,1.8,0.3l25.5-0.4c2.1,0,3.1-2,4.8-4.1c1.6-2,1.7-3.9,2.5-6.8
																	c0.7-2.9,0.2-6.1,0.1-8.6c-0.2-2.6-0.5-3.4-2.6-3.3l-30.1,0.4l0,0c-4.2,0.1-7.5,5.2-7.5,11.5
																	C838,128.5,840.3,132.9,843.6,134z"/>
															</g>
														</g>
														<path className="st4" d="M844,112c0.4,0,0.8,0.1,1.2,0.2c2.8,1,5,5.3,5,10.4c0,3-0.7,5.7-2,7.8c-1.2,1.9-2.7,3-4.2,3
															c-3.4,0.1-6.2-4.8-6.2-10.6C837.8,117.1,840.6,112.1,844,112z"/>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										
											<linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="741.2642" y1="460.9839" x2="732.7603" y2="484.0888" gradientTransform="matrix(1 0 0 -1 0 536.9373)">
											<stop  offset="0" style={{stopColor:"#A05524"}}/>
											<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
											<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
											<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
											<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
											<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
											<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
											<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
											<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
											<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
											<stop  offset="1" style={{stopColor:"#A05524"}}/>
										</linearGradient>
										<path className="st8" d="M729.2,81.1c0.7,0,1.3-0.1,1.9-0.3l21.2-7.8c2.1-0.8,3.6-2.8,4.1-5.6c0.5-2.6,0.2-5.7-0.9-8.7
											c-1.1-3-2.9-5.6-5-7.2c-2.3-1.8-4.7-2.3-6.8-1.6l-21.2,7.8l0,0c-4.2,1.5-5.6,7.8-3.3,14.3C721.4,77.5,725.4,81.1,729.2,81.1z"
											/>
									</g>
								</g>
							</g>
							<path className="st4" d="M721.1,59.1c0.4-0.2,0.9-0.2,1.3-0.2c3.2,0,7,3.6,8.9,8.8c1.1,3,1.4,6,0.9,8.6c-0.5,2.3-1.6,3.9-3.1,4.5
								c-3.4,1.2-8-2.7-10.2-8.6C716.7,66.4,717.7,60.4,721.1,59.1z"/>
							<g>
								<g>
									<g>
										
											<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="1118.312" y1="113.323" x2="1109.8115" y2="136.4181" gradientTransform="matrix(0.9837 0.18 0.18 -0.9837 -338.6462 -3.6498)">
											<stop  offset="0" style={{stopColor:"#A05524"}}/>
											<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
											<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
											<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
											<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
											<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
											<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
											<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
											<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
											<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
											<stop  offset="1" style={{stopColor:"#A05524"}}/>
										</linearGradient>
										<path className="st9" d="M768.9,89c0.6,0.1,1.3,0.1,2,0l22.2-3.8c2.2-0.4,4-2.1,5.1-4.7c1-2.5,1.3-5.6,0.7-8.7
											c-0.5-3.1-1.8-6-3.6-8c-1.9-2.2-4.2-3.1-6.4-2.8l-22.2,3.8l0,0c-4.4,0.8-7,6.7-5.8,13.5C761.9,84.1,765.2,88.4,768.9,89z"/>
									</g>
								</g>
							</g>
							<path className="st4" d="M764.9,66c0.4-0.1,0.9-0.1,1.3,0c3.1,0.6,6.2,4.8,7.2,10.2c0.5,3.1,0.3,6.2-0.7,8.6
								c-0.9,2.2-2.3,3.6-3.9,3.9c-3.5,0.6-7.4-4.1-8.5-10.2C759.3,72.3,761.4,66.6,764.9,66z"/>
							<g>
								<g>
									<g>
										
											<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="884.4785" y1="413.2302" x2="875.9745" y2="436.3351" gradientTransform="matrix(1.0098 5.665493e-02 5.948732e-02 -1.0603 -158.0537 501.0623)">
											<stop  offset="0" style={{stopColor:"#A05524"}}/>
											<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
											<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
											<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
											<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
											<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
											<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
											<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
											<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
											<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
											<stop  offset="1" style={{stopColor:"#A05524"}}/>
										</linearGradient>
										<path className="st10" d="M747.1,117.8c0.7,0,1.3,0,2-0.3l21.8-7c2.2-0.7,3.8-2.7,4.5-5.7c0.7-2.8,0.6-6.1-0.4-9.3
											c-0.9-3.2-2.6-6.1-4.6-7.9c-2.2-2-4.6-2.7-6.7-2l-21.8,7l0,0c-4.3,1.4-6.2,8-4.2,15C739.4,113.5,743.3,117.6,747.1,117.8z"/>
									</g>
								</g>
							</g>
							<path className="st4" d="M740.3,94.1c0.4-0.1,0.9-0.2,1.3-0.2c3.2,0.2,6.8,4.3,8.4,9.8c0.9,3.2,1.1,6.5,0.4,9.2
								c-0.6,2.5-1.8,4.1-3.4,4.6c-3.5,1.1-8-3.3-9.8-9.6C735.4,101.5,736.8,95.2,740.3,94.1z"/>
							<g>
								<g>
									<g>
										
											<linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="1270.1727" y1="184.1205" x2="1261.6692" y2="207.2246" gradientTransform="matrix(1.0604 0.1991 0.196 -1.0437 -586.3115 63.6839)">
											<stop  offset="0" style={{stopColor:"#A05524"}}/>
											<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
											<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
											<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
											<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
											<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
											<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
											<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
											<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
											<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
											<stop  offset="1" style={{stopColor:"#A05524"}}/>
										</linearGradient>
										<path className="st11" d="M782.7,127.4c0.7,0.1,1.4,0.1,2.1,0l24-3.9c2.4-0.4,4.3-2.2,5.5-5c1.1-2.6,1.4-5.9,0.8-9.3
											c-0.6-3.3-1.9-6.4-3.8-8.5c-2-2.3-4.5-3.4-6.9-3l-24,3.9l0,0c-4.8,0.8-7.5,7-6.3,14.3C775.1,122.1,778.7,126.7,782.7,127.4z"
											/>
									</g>
								</g>
							</g>
							<path className="st4" d="M778.4,102.9c0.5-0.1,1-0.1,1.4,0c3.4,0.6,6.7,5.2,7.7,10.9c0.6,3.3,0.3,6.6-0.7,9.2c-1,2.3-2.5,3.8-4.2,4.1
								c-3.8,0.6-8-4.4-9.1-11C772.3,109.6,774.6,103.5,778.4,102.9z"/>
							<g>
								<g>
									<g>
										<g>
											
												<linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="1285.9385" y1="204.9484" x2="1277.4347" y2="228.0525" gradientTransform="matrix(1.0604 0.1991 0.196 -1.0437 -586.3115 63.6839)">
												<stop  offset="0" style={{stopColor:"#A05524"}}/>
												<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
												<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
												<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
												<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
												<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
												<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
												<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
												<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
												<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
												<stop  offset="1" style={{stopColor:"#A05524"}}/>
											</linearGradient>
											<path className="st12" d="M803.5,108.8c0.7,0.1,1.4,0.1,2.1,0l24-3.9c2.4-0.4,4.3-2.2,5.5-5c1.1-2.6,1.4-5.9,0.8-9.3
												c-0.6-3.3-1.9-6.4-3.8-8.5c-2-2.3-4.5-3.4-6.9-3l-24,3.9l0,0c-4.8,0.8-7.5,7-6.3,14.3C795.9,103.5,799.5,108.1,803.5,108.8z"
												/>
										</g>
									</g>
								</g>
								<path className="st4" d="M799.2,84.3c0.5-0.1,1-0.1,1.4,0c3.4,0.6,6.7,5.2,7.7,10.9c0.6,3.3,0.3,6.6-0.7,9.2c-1,2.3-2.5,3.8-4.2,4.1
									c-3.8,0.6-8-4.4-9.1-11C793.2,91,795.4,84.9,799.2,84.3z"/>
							</g>
							<g>
								<g>
									<g>
										
											<linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="-277.0908" y1="1558.3547" x2="-285.5948" y2="1581.4597" gradientTransform="matrix(0.7653 -0.4062 -0.4487 -0.8452 1571.3049 1274.3088)">
											<stop  offset="0" style={{stopColor:"#A05524"}}/>
											<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
											<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
											<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
											<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
											<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
											<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
											<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
											<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
											<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
											<stop  offset="1" style={{stopColor:"#A05524"}}/>
										</linearGradient>
										<path className="st13" d="M653,79.1c0.5-0.3,0.9-0.6,1.3-1.1L667,62.8c1.3-1.5,1.5-3.8,0.7-6.4c-0.8-2.4-2.4-4.9-4.6-7
											c-2.2-2.1-4.7-3.5-7-4.1c-2.5-0.6-4.6-0.1-5.9,1.4l-12.7,15.2l0,0c-2.5,3-0.8,8.9,3.9,13.4C645.4,79.2,650.1,80.6,653,79.1z"
											/>
									</g>
								</g>
							</g>
							<path className="st4" d="M636.9,63.8c0.3-0.3,0.6-0.5,0.9-0.7c2.4-1.3,7,0.2,10.7,3.8c2.2,2.1,3.8,4.5,4.5,6.9c0.7,2.2,0.5,4-0.4,5.1
								c-2,2.4-7.4,1-11.6-3.1C636.8,71.7,634.9,66.2,636.9,63.8z"/>
							<path className="st14" d="M729.9,79.3c0.6,0,1.2-0.1,1.8-0.3l19.3-7.1c1.9-0.7,3.3-2.5,3.8-5.1c0.5-2.4,0.2-5.2-0.8-7.9
								s-2.6-5.1-4.5-6.6c-2.1-1.6-4.2-2.1-6.2-1.4L724,58l0,0c-3.8,1.4-5.1,7.1-3,13C722.8,76,726.5,79.3,729.9,79.3z M744,52.8
								c1.2-0.5,2.7-0.1,4.2,1.1c1.6,1.3,3,3.3,3.9,5.7s1.1,4.8,0.7,6.9c-0.4,1.9-1.3,3.1-2.5,3.6l-15.4,5.6c0.2-0.5,0.4-1.1,0.5-1.7
								c0.5-2.4,0.2-5.2-0.8-7.9c-1.3-3.6-3.6-6.3-6.1-7.6L744,52.8z M724.7,59.8c0.3-0.1,0.7-0.2,1-0.2c2.5,0,5.6,2.9,7.1,7
								c0.9,2.4,1.1,4.8,0.7,6.9c-0.4,1.9-1.3,3.1-2.5,3.6c-2.7,1-6.4-2.1-8.1-6.8C721.2,65.6,722,60.8,724.7,59.8z"/>
							<path className="st14" d="M729.2,74.9c0.3,0,0.6-0.1,0.9-0.2c2.2-0.8,2.8-4.1,1.5-7.7c-1.3-3.6-3.9-5.7-6.1-4.9
								c-1.1,0.4-1.9,1.5-2.1,3.1c-0.2,1.4,0,3,0.6,4.6C725.2,72.9,727.3,74.9,729.2,74.9z M725.4,65.5c0.1-0.8,0.4-1.4,0.8-1.5
								c0.1,0,0.1,0,0.2,0c0.9,0,2.4,1.3,3.3,3.8l0,0c1,2.7,0.5,4.9-0.3,5.1c-0.8,0.3-2.5-1-3.5-3.8C725.5,67.9,725.3,66.5,725.4,65.5z"
								/>
							<path className="st14" d="M770.4,89.2c0.3,0,0.6,0,0.9-0.1l21-3.9c4.2-0.8,6.5-6.4,5.3-12.8c-0.5-3-1.8-5.6-3.5-7.5
								c-1.8-2-4-2.9-6-2.5l-21,3.9c-2.1,0.4-3.8,2-4.7,4.5c-0.9,2.4-1.1,5.3-0.6,8.3C762.9,85,766.6,89.2,770.4,89.2z M788.6,64.3
								c1.4-0.2,2.9,0.4,4.2,1.9c1.5,1.6,2.5,3.9,3,6.5c0.9,5.1-0.7,9.9-3.7,10.5l-16.8,3.1c1.6-2.4,2.2-6.1,1.5-10
								c-0.5-3-1.8-5.6-3.5-7.5c-0.5-0.5-1-1-1.5-1.3L788.6,64.3z M764.3,71.5c0.7-1.9,1.9-3,3.2-3.3c0.2,0,0.4-0.1,0.6-0.1
								c1.2,0,2.5,0.7,3.6,2c1.5,1.6,2.5,3.9,3,6.5c0.9,5.1-0.7,9.9-3.7,10.5c-3,0.5-6.3-3.3-7.2-8.4C763.3,76.1,763.5,73.5,764.3,71.5z
								"/>
							<path className="st14" d="M770.1,84.9c0.2,0,0.3,0,0.5,0c2.4-0.4,3.6-3.9,2.9-8c-0.3-1.8-1-3.5-2-4.6c-1.1-1.4-2.3-2-3.6-1.8
								c-1.2,0.2-2.2,1.3-2.7,2.9c-0.4,1.4-0.5,3.2-0.2,5C765.7,82.3,767.9,84.9,770.1,84.9z M767.1,74c0.3-0.9,0.7-1.5,1.2-1.5h0.1
								c0.4,0,1,0.4,1.5,1c0.7,0.9,1.3,2.3,1.6,3.8c0.5,2.9-0.2,5.4-1.3,5.6c-1,0.2-2.7-1.9-3.2-4.8C766.7,76.6,766.8,75.1,767.1,74z"/>
							<path className="st14" d="M750.7,103.5c-0.5-1.7-1.3-3.2-2.2-4.2c-1.1-1.2-2.4-1.7-3.6-1.4c-1.2,0.3-2,1.4-2.3,3
								c-0.3,1.4-0.2,3.1,0.3,4.8s1.3,3.2,2.2,4.2s1.9,1.5,2.8,1.5c0.2,0,0.5,0,0.7-0.1c1.2-0.3,2-1.4,2.3-3
								C751.3,106.8,751.2,105.2,750.7,103.5z M749.1,107.9c-0.2,0.8-0.5,1.4-0.9,1.5s-1-0.2-1.5-0.8c-0.7-0.8-1.4-2.1-1.8-3.4
								c-0.4-1.4-0.5-2.8-0.3-3.9c0.2-0.8,0.5-1.4,0.9-1.5c0.1,0,0.1,0,0.2,0c0.4,0,0.9,0.3,1.4,0.8c0.7,0.8,1.4,2.1,1.8,3.4
								C749.2,105.4,749.3,106.8,749.1,107.9z"/>
							<path className="st14" d="M763.5,88.2l-19.8,5.6c-2,0.6-3.4,2.3-4.1,4.8c-0.7,2.4-0.6,5.2,0.2,8s2.2,5.2,4,6.9
								c1.5,1.4,3.1,2.1,4.7,2.1c0.5,0,0.9-0.1,1.4-0.2l19.8-5.6c3.9-1.1,5.7-6.7,3.9-12.8C771.9,90.9,767.5,87.1,763.5,88.2z
								M745.2,112c-1.5-1.4-2.8-3.5-3.5-6c-0.7-2.4-0.8-4.9-0.2-6.9c0.5-1.8,1.5-3,2.8-3.4c0.3-0.1,0.5-0.1,0.8-0.1
								c1.1,0,2.2,0.5,3.3,1.5c1.5,1.4,2.8,3.5,3.5,6c0.7,2.4,0.8,4.9,0.2,6.9c-0.5,1.8-1.5,3-2.8,3.4
								C748.1,113.8,746.6,113.3,745.2,112z M769.1,107.8l-15.7,4.5c0.3-0.5,0.5-1.1,0.7-1.7c0.7-2.4,0.6-5.2-0.2-8s-2.2-5.2-4-6.9
								c-0.5-0.4-1-0.8-1.5-1.1l15.7-4.5c2.8-0.8,6.2,2.6,7.6,7.4C773,102.3,771.9,107,769.1,107.8z"/>
							<path className="st14" d="M801.4,101.1c1,1.1,2.1,1.7,3.2,1.7c0.2,0,0.4,0,0.6-0.1c1.3-0.2,2.4-1.3,2.9-2.9c0.5-1.4,0.6-3.2,0.3-5
								c-0.7-3.9-3.3-6.6-5.8-6.1s-4,3.9-3.2,7.8C799.6,98.4,800.4,100,801.4,101.1z M802.9,90.8c0.1,0,0.1,0,0.2,0
								c1.2,0,2.8,1.8,3.3,4.6c0.3,1.4,0.2,2.8-0.2,3.9c-0.3,0.9-0.9,1.5-1.4,1.6c-0.6,0.1-1.3-0.2-1.9-1c-0.8-0.9-1.3-2.2-1.6-3.6
								C800.7,93.3,801.7,91,802.9,90.8z"/>
							<path className="st14" d="M830.7,82.9c-1.9-1.9-4.1-2.7-6.3-2.3l-22.6,4.2c-4.3,0.8-7,6.4-5.8,12.5c0.2,1.1,0.5,2.1,0.9,3l-16.1,3
								c-4.3,0.8-7,6.4-5.8,12.5c1,5.6,4.9,9.6,8.9,9.6c0.4,0,0.7,0,1.1-0.1l22.6-4.2c4.3-0.8,7-6.4,5.8-12.5c-0.2-1.1-0.5-2.1-0.9-3
								l16.1-3c2.2-0.4,4-2,5.1-4.4c1-2.3,1.3-5.2,0.8-8S832.5,84.7,830.7,82.9z M802.2,86.7c0.2,0,0.5-0.1,0.7-0.1c3,0,6,3.4,6.9,8l0,0
								c0.9,5-1,9.5-4.2,10.1c-3.3,0.6-6.7-3-7.6-7.9C797,91.9,798.9,87.3,802.2,86.7z M776.9,115.3c-0.9-5,1-9.5,4.2-10.1
								c0.2,0,0.5-0.1,0.7-0.1c3,0,6,3.4,6.9,8c0.9,5-1,9.5-4.2,10.1C781.2,123.9,777.8,120.3,776.9,115.3z M811.3,109
								c0.9,5-1,9.5-4.2,10.1l-18.1,3.3c1.7-2.4,2.4-5.9,1.7-9.7c-0.7-3.7-2.6-6.8-5.1-8.4l12.2-2.3c1.7,2.9,4.3,4.7,7,4.7
								c0.4,0,0.7,0,1.1-0.1l4.4-0.8C810.7,106.9,811.1,107.9,811.3,109z M831.7,97.4c-0.8,1.8-2.1,3-3.6,3.3L810,104
								c1.7-2.4,2.4-5.9,1.7-9.7l0,0c-0.7-3.7-2.7-6.8-5.1-8.4l18.1-3.3c1.5-0.3,3.1,0.3,4.5,1.8c1.5,1.5,2.6,3.7,3.1,6.2
								C832.8,93,832.5,95.4,831.7,97.4z"/>
							<path className="st14" d="M787.1,113.4c-0.3-1.7-1-3.3-2-4.4c-1.1-1.3-2.4-1.8-3.6-1.6c-2.4,0.5-3.8,3.8-3.1,7.6c0.6,3.5,2.8,6,5,6
								c0.2,0,0.4,0,0.5-0.1c1.3-0.2,2.3-1.2,2.8-2.8C787.4,116.8,787.5,115.1,787.1,113.4z M785,117.5c-0.3,0.8-0.8,1.4-1.3,1.5
								c-1.1,0.2-2.7-1.5-3.3-4.4c-0.5-2.7,0.4-5,1.5-5.2c0.1,0,0.1,0,0.2,0c0.5,0,1,0.3,1.6,0.9c0.7,0.8,1.3,2.1,1.5,3.4l0,0
								C785.4,115.1,785.4,116.5,785,117.5z"/>
							<path className="st14" d="M648,75.4c0.8,0.2,1.7,0.4,2.5,0.4c1.6,0,3-0.5,4.2-1.4c0.6-0.5,1.1-1.1,1.5-1.8l0,0l8-9.4
								c1.4-1.7,1.9-4,1.2-6.5c-0.6-2.4-2.1-4.7-4.2-6.5c-4.5-3.8-10.5-4.2-13.3-0.9l-6.8,8l0,0c-3.5,2.8-3.4,8.9,0.3,13.6
								C643.2,73.1,645.5,74.7,648,75.4z M649.5,50.5c2.1-2.5,6.8-2,10.5,1.1c1.8,1.5,3.1,3.5,3.6,5.5c0.5,1.9,0.2,3.5-0.8,4.7l-5.5,6.5
								c0-2.5-1-5.2-2.9-7.6c-1.8-2.2-4.1-3.9-6.5-4.5c-1-0.3-1.9-0.4-2.9-0.3L649.5,50.5z M641.4,59.9L641.4,59.9l1.1-1.2
								c0.8-0.6,1.8-0.9,2.8-0.9c0.6,0,1.2,0.1,1.9,0.3c2,0.6,4,1.9,5.5,3.9c3,3.9,3.3,8.7,0.7,10.8c-1.2,1-3,1.2-4.9,0.7
								c-2-0.6-4-1.9-5.5-3.9C640.5,66.3,639.9,62.3,641.4,59.9z"/>
							<path className="st14" d="M648,71.5c0.6,0.2,1.2,0.3,1.7,0.3c0.9,0,1.7-0.3,2.3-0.8c1.1-0.8,1.5-2.2,1.4-3.8c-0.1-1.5-0.8-3-1.9-4.4
								c-1.1-1.3-2.4-2.3-3.8-2.8c-1.5-0.5-3-0.4-4,0.5c-1.1,0.8-1.5,2.2-1.4,3.8c0.1,1.5,0.8,3,1.9,4.4C645.3,70,646.6,71,648,71.5z
								M645,62.1c0.3-0.3,0.7-0.3,1.1-0.3c0.4,0,0.8,0.1,1.1,0.2c1,0.3,2.1,1.1,2.9,2.2c0.8,1,1.3,2.2,1.4,3.3c0,0.5,0,1.5-0.6,2.1
								c-0.7,0.5-1.6,0.3-2.1,0.2c-1-0.3-2.1-1.1-2.9-2.2c-0.8-1-1.3-2.2-1.4-3.3C644.3,63.6,644.3,62.6,645,62.1z"/>
							<g>
								<g>
									<g>
										<g>
											
												<linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="895.3372" y1="178.051" x2="886.8331" y2="201.1559" gradientTransform="matrix(1.0287 4.131929e-02 4.261932e-02 -1.0611 -213.3949 529.4273)">
												<stop  offset="0" style={{stopColor:"#A05524"}}/>
												<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
												<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
												<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
												<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
												<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
												<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
												<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
												<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
												<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
												<stop  offset="1" style={{stopColor:"#A05524"}}/>
											</linearGradient>
											<path className="st15" d="M702.4,382.4c0.7,0,1.3-0.1,2-0.3l22.1-7.4c2.2-0.7,3.8-2.8,4.5-5.7c0.7-2.8,0.5-6.1-0.5-9.3
												c-1-3.2-2.7-6-4.8-7.9c-2.2-2-4.7-2.7-6.9-1.9l-22.1,7.4l0,0c-4.4,1.5-6.1,8.1-4,15C694.5,378.3,698.5,382.3,702.4,382.4z"/>
										</g>
									</g>
								</g>
								<path className="st4" d="M695,358.8c0.4-0.1,0.9-0.2,1.4-0.2c3.3,0.1,7,4.2,8.8,9.7c1,3.2,1.2,6.5,0.6,9.2c-0.6,2.5-1.8,4.1-3.4,4.7
									c-3.5,1.2-8.2-3.2-10.1-9.5C690.2,366.3,691.5,360,695,358.8z"/>
								<path className="st14" d="M722.9,352.5c-2.1-1.7-4.3-2.2-6.2-1.5l-19.3,7.1c-1.9,0.7-3.2,2.6-3.7,5.2c-0.5,2.5-0.1,5.4,0.9,8.2
									c1.9,5.2,5.7,8.7,9.2,8.7c0.6,0,1.2-0.1,1.7-0.3l19.3-7.1c1.9-0.7,3.2-2.6,3.7-5.2c0.5-2.5,0.1-5.4-0.9-8.2
									C726.5,356.5,724.8,354,722.9,352.5z M696.4,370.8c-0.9-2.5-1.2-5-0.8-7.2c0.4-1.9,1.2-3.2,2.5-3.7c0.3-0.1,0.7-0.2,1-0.2
									c1,0,2.1,0.5,3.3,1.4c1.7,1.4,3.1,3.5,4,6c1.8,4.9,1.1,9.9-1.6,10.9C702,379,698.2,375.7,696.4,370.8z M726.5,367.1
									c-0.4,1.9-1.2,3.2-2.5,3.7l-15.4,5.7c1.1-2.6,1-6.3-0.4-10.1c-1-2.8-2.7-5.3-4.6-6.8c-0.5-0.4-1.1-0.8-1.7-1.1l15.4-5.7
									c1.2-0.5,2.7,0,4.3,1.2c1.7,1.4,3.1,3.5,4,6C726.6,362.5,726.9,365,726.5,367.1z"/>
								<path className="st14" d="M705.1,367.6c-1.3-3.6-3.9-6.8-6.4-5.9c-1.1,0.4-1.8,1.6-1.9,3.4c-0.1,1.5,0.2,3.3,0.9,5.2
									c0.7,1.8,1.6,3.4,2.7,4.5c1,1,2,1.5,2.9,1.5c0.3,0,0.6,0,0.8-0.1c1.1-0.4,1.8-1.6,1.9-3.4C706.1,371.3,705.7,369.5,705.1,367.6z
									M703.4,374.4c-0.3,0.1-0.8-0.1-1.6-0.9c-0.9-0.9-1.7-2.3-2.2-3.8s-0.9-3.1-0.8-4.4c0.1-1,0.3-1.6,0.6-1.7h0.1
									c0.7,0,2.5,1.6,3.7,4.7c0.6,1.5,0.9,3.1,0.8,4.4C703.9,373.7,703.6,374.3,703.4,374.4z"/>
							</g>
							<g>
								<g>
									<g>
										<g>
											
												<linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="937.7338" y1="183.2334" x2="929.2228" y2="206.3573" gradientTransform="matrix(1.0781 4.330190e-02 4.261931e-02 -1.0611 -351.3687 523.8888)">
												<stop  offset="0" style={{stopColor:"#A05524"}}/>
												<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
												<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
												<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
												<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
												<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
												<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
												<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
												<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
												<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
												<stop  offset="1" style={{stopColor:"#A05524"}}/>
											</linearGradient>
											<path className="st16" d="M653.9,375c0.7,0,1.4-0.1,2.1-0.3l23.2-7.3c2.3-0.7,4-2.8,4.7-5.7c0.7-2.8,0.5-6.1-0.6-9.3
												c-1.1-3.2-2.8-6-5-7.9c-2.4-2-4.9-2.7-7.2-2l-23.1,7.3l0,0c-4.6,1.5-6.4,8.1-4.1,15C645.7,370.8,649.9,374.9,653.9,375z"/>
										</g>
									</g>
								</g>
								<path className="st4" d="M646.2,351.4c0.5-0.1,0.9-0.2,1.4-0.2c3.4,0.1,7.4,4.2,9.2,9.7c1.1,3.2,1.3,6.5,0.6,9.2
									c-0.6,2.5-1.9,4.1-3.6,4.6c-3.7,1.2-8.6-3.2-10.6-9.5C641.1,358.9,642.5,352.6,646.2,351.4z"/>
								<path className="st14" d="M657.1,360.4c-1.3-3.6-3.9-6.8-6.4-5.9c-1.1,0.4-1.8,1.6-1.9,3.4c-0.1,1.5,0.2,3.3,0.9,5.2
									c1.2,3.2,3.4,6,5.5,6c0.3,0,0.6,0,0.8-0.2c1.1-0.4,1.8-1.6,1.9-3.4C658.1,364,657.7,362.2,657.1,360.4z M655.4,367.1
									c-0.6,0.2-2.6-1.4-3.8-4.7c-0.6-1.5-0.9-3.1-0.8-4.4c0.1-1,0.3-1.6,0.6-1.7h0.1c0.7,0,2.5,1.6,3.7,4.7c0.6,1.5,0.9,3.1,0.8,4.4
									C655.9,366.4,655.6,367,655.4,367.1z"/>
								<path className="st14" d="M668.7,343.7l-19.3,7.1c-3.8,1.4-5.1,7.3-2.8,13.4c1.9,5.2,5.7,8.7,9.2,8.7c0.6,0,1.2-0.1,1.7-0.3
									l19.3-7.1c1.9-0.7,3.2-2.6,3.7-5.2c0.5-2.5,0.1-5.4-0.9-8.2C677.3,346,672.5,342.3,668.7,343.7z M648.4,363.6
									c-1.8-4.9-1.1-9.9,1.6-10.9c0.3-0.1,0.7-0.2,1-0.2c2.6,0,5.7,3.1,7.3,7.4c1.8,4.9,1.1,9.9-1.6,10.9
									C654,371.7,650.2,368.5,648.4,363.6z M678.5,359.9c-0.4,1.9-1.2,3.2-2.5,3.7l-15.4,5.7c1.1-2.6,1-6.3-0.4-10.1
									c-1.4-3.8-3.8-6.6-6.3-7.9l15.4-5.7c2.7-1,6.5,2.3,8.3,7.2C678.6,355.3,678.9,357.8,678.5,359.9z"/>
							</g>
							<path className="st14" d="M592.4,197.9c0.8-0.3,1.5-0.8,2.2-1.3c1.2-1,2.1-2.3,2.4-3.7c0.2-0.8,0.2-1.6,0.1-2.4l0,0l0.4-12.4
								c0.1-2.2-1-4.3-3.1-5.8c-2-1.5-4.6-2.4-7.4-2.4c-5.9-0.2-10.8,3.2-10.9,7.6l-0.3,10.5l0,0c-1,4.4,2.9,9.1,8.8,10.4
								C587.2,199.1,590,198.9,592.4,197.9z M578,177.6c0.1-3.3,4.1-5.8,8.9-5.7c2.4,0.1,4.6,0.8,6.2,2c1.5,1.2,2.3,2.6,2.3,4.2
								l-0.3,8.5c-1.6-1.9-4-3.4-7-4.1c-2.8-0.6-5.6-0.5-7.9,0.5c-0.9,0.4-1.8,0.9-2.4,1.5L578,177.6z M577.5,190L577.5,190l0.1-1.7
								c0.3-0.9,0.8-1.8,1.7-2.5c0.5-0.4,1-0.7,1.7-1c1.9-0.8,4.3-1,6.7-0.4c4.8,1.1,8,4.7,7.3,8c-0.4,1.5-1.6,2.8-3.4,3.6
								c-1.9,0.8-4.3,1-6.7,0.4C580.8,195.6,577.9,192.8,577.5,190z"/>
							<path className="st14" d="M590,194.9c0.6-0.2,1.1-0.5,1.5-0.8c0.7-0.5,1.1-1.2,1.3-2.1c0.3-1.3-0.2-2.7-1.3-3.8
								c-1-1.1-2.5-1.9-4.2-2.2c-1.7-0.4-3.3-0.3-4.7,0.2c-1.5,0.6-2.5,1.6-2.8,2.9s0.2,2.7,1.3,3.8c1,1.1,2.5,1.9,4.2,2.2
								C586.9,195.5,588.6,195.4,590,194.9z M581.7,189.4c0.1-0.4,0.3-0.7,0.6-0.9c0.3-0.3,0.7-0.4,0.9-0.5c1-0.4,2.3-0.4,3.6-0.1
								s2.4,0.9,3.2,1.7c0.4,0.4,1,1.2,0.8,2s-1.1,1.3-1.6,1.5c-1,0.4-2.3,0.4-3.6,0.1s-2.4-0.9-3.2-1.7
								C582.1,191,581.5,190.3,581.7,189.4z"/>
							<path className="st14" d="M854.2,142.3h-26.1c1.1-1.9,1.6-4.8,1.6-7.7c0-2.9-0.6-5.8-1.6-7.7h11.6c0.6,2.9,1.8,5.1,3.6,5.4v0.1H874
								l1.3-2h-28.8c1.1-1.9,1.6-4.8,1.6-7.7s-0.5-5.8-1.6-7.6h32l-0.3-2H844c-0.1,0-0.2,0-0.4,0s-0.2,0-0.4,0l0,0l0,0
								c-2.6,0.4-4,5-4,9.6c0,0.8,0,1.5,0.1,2.2h-13.8l0,0c-0.1,0-0.2,0-0.3,0c-2.9,0-4.4,4.9-4.4,9.7s1.5,9.7,4.4,9.7
								c0.1,0,0.2,0,0.3,0l0,0h28.6v-2L854.2,142.3L854.2,142.3z M841.3,122.7c0-4.6,1.4-7.3,2.2-7.6h0.3c0.9,0.3,2.2,3,2.2,7.6
								c0,5-1.6,7.7-2.4,7.7S841.3,127.7,841.3,122.7z M825.3,126.9c0.8,0,2.4,2.7,2.4,7.7s-1.6,7.7-2.4,7.7s-2.4-2.7-2.4-7.7
								S824.4,126.9,825.3,126.9z"/>
							<path className="st14" d="M827,134.6c0-1.8-0.2-6.1-1.7-6.1s-1.7,4.2-1.7,6.1s0.2,6.1,1.7,6.1S827,136.4,827,134.6z M825.7,134.6
								c0,2.2-0.2,3.6-0.5,4.4c-0.2-0.7-0.5-2.2-0.5-4.4s0.2-3.6,0.5-4.4C825.5,131,825.7,132.4,825.7,134.6z"/>
							<path className="st14" d="M842,122.7c0,1.8,0.2,6.1,1.7,6.1s1.7-4.2,1.7-6.1s-0.2-6.1-1.7-6.1S842,120.9,842,122.7z M843.3,122.7
								c0-2.2,0.2-3.6,0.5-4.4c0.2,0.7,0.5,2.2,0.5,4.4s-0.2,3.6-0.5,4.4C843.5,126.3,843.3,124.8,843.3,122.7z"/>
							<g>
								<g>
									<g>
										<g>
											
												<linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="734.7524" y1="185.0526" x2="725.5226" y2="210.1292" gradientTransform="matrix(1 0 0 -1 0 536.9373)">
												<stop  offset="0" style={{stopColor:"#A05524"}}/>
												<stop  offset="9.465214e-02" style={{stopColor:"#BF6426"}}/>
												<stop  offset="0.1914" style={{stopColor:"#D87026"}}/>
												<stop  offset="0.2598" style={{stopColor:"#E37626"}}/>
												<stop  offset="0.3546" style={{stopColor:"#E67D25"}}/>
												<stop  offset="0.5111" style={{stopColor:"#EF8F21"}}/>
												<stop  offset="0.6086" style={{stopColor:"#F99F1C"}}/>
												<stop  offset="0.6601" style={{stopColor:"#F4981E"}}/>
												<stop  offset="0.7413" style={{stopColor:"#EB8623"}}/>
												<stop  offset="0.805" style={{stopColor:"#E37626"}}/>
												<stop  offset="1" style={{stopColor:"#A05524"}}/>
											</linearGradient>
											<path className="st17" d="M721.3,356.7c0.7,0,1.3-0.1,2-0.3l22.1-7.4c2.2-0.7,3.8-2.8,4.5-5.7c0.7-2.8,0.5-6.1-0.5-9.3
												c-1-3.2-2.7-6-4.8-7.9c-2.2-2-4.7-2.7-6.9-1.9l-22.1,7.4l0,0c-4.4,1.5-6.1,8.1-4,15C713.5,352.6,717.5,356.6,721.3,356.7z"/>
										</g>
									</g>
								</g>
								<path className="st4" d="M714,333.1c0.4-0.1,0.9-0.2,1.4-0.2c3.3,0.1,7,4.2,8.8,9.7c1,3.2,1.2,6.5,0.6,9.2c-0.6,2.5-1.8,4.1-3.4,4.7
									c-3.5,1.2-8.2-3.2-10.1-9.5C709.1,340.6,710.5,334.3,714,333.1z"/>
							</g>
							<path className="st14" d="M736.2,325.3l-19.3,7.1c-3.8,1.4-5.1,7.3-2.8,13.4c1.9,5.2,5.7,8.7,9.2,8.7c0.6,0,1.2-0.1,1.7-0.3l19.3-7.1
								c1.9-0.7,3.2-2.6,3.7-5.2c0.4-2.5,0.1-5.4-0.9-8.2C744.8,327.6,740,323.9,736.2,325.3z M716,345.2c-1.8-4.9-1.1-9.9,1.6-10.9
								c0.3-0.1,0.7-0.2,1-0.2c2.6,0,5.7,3.1,7.3,7.4c1.8,4.9,1.1,9.9-1.6,10.9C721.6,353.4,717.8,350.1,716,345.2z M746,341.6
								c-0.4,1.9-1.2,3.2-2.5,3.7l-15.4,5.7c1.1-2.6,1-6.3-0.4-10.1c-1.4-3.8-3.8-6.6-6.3-7.9l15.4-5.7c2.7-1,6.5,2.3,8.3,7.2
								C746.1,336.9,746.4,339.4,746,341.6z"/>
							<path className="st14" d="M724.6,341.9c-1.3-3.6-3.9-6.8-6.4-5.9c-1.1,0.4-1.8,1.6-1.9,3.4c-0.1,1.5,0.2,3.3,0.9,5.2
								c1.2,3.2,3.4,6,5.5,6c0.3,0,0.6,0,0.8-0.2c1.1-0.4,1.8-1.6,1.9-3.4C725.6,345.5,725.3,343.7,724.6,341.9z M722.9,348.6
								c-0.6,0.2-2.6-1.4-3.8-4.7c-0.6-1.5-0.9-3.1-0.8-4.4c0.1-1,0.3-1.6,0.6-1.7h0.1c0.7,0,2.5,1.6,3.7,4.7c0.6,1.5,0.9,3.1,0.8,4.4
								C723.5,348,723.2,348.5,722.9,348.6z"/>
						</g>
					</g>
					<g>
						<path className="st14" d="M827,134.5c0-1.8-0.2-6.1-1.7-6.1s-1.7,4.2-1.7,6.1s0.2,6.1,1.7,6.1S827,136.3,827,134.5z M825.7,134.5
							c0,2.2-0.2,3.6-0.5,4.4c-0.2-0.7-0.5-2.2-0.5-4.4s0.2-3.6,0.5-4.4C825.5,130.8,825.7,132.3,825.7,134.5z"/>
						<path className="st14" d="M842,122.6c0,1.8,0.2,6.1,1.7,6.1s1.7-4.2,1.7-6.1s-0.2-6.1-1.7-6.1S842,120.7,842,122.6z M843.3,122.6
							c0-2.2,0.2-3.6,0.5-4.4c0.2,0.7,0.5,2.2,0.5,4.4s-0.2,3.6-0.5,4.4C843.5,126.2,843.3,124.7,843.3,122.6z"/>
						<path className="st14" d="M859.8,130.3c0.2,1,0.4,1.5,0.4,2.6c0,5.1-2.7,9.2-6,9.2h-18.4l-0.5,2.1h18.9c4.4,0,8-5.2,8-11.3
							c0-1.1-0.1-1.6-0.3-2.6L859.8,130.3z"/>
					</g>
				</g>
				<g id="first">
					<g>
						<g>
							<polygon className="st2" points="871.7,394.7 871.7,394.8 871.7,394.8 			"/>
							<g>
								<path className="st2" d="M883.2,99.6C876.7,79,847,65,825.3,54.7c-23.4-11.1-58.7-20.5-70.3-23.2c-29.1-6.8-50.7-11.5-83.8-15.3
									c-11.6-1.3-21.4-2.1-33.4,0.9c-45,11.5-72.3,52-82,68.9c-32.6,57-29.8,117.8-29.4,124.5c2,32.4,9.5,70.2,24.3,101.8
									c0.1,0.2,0.1,0.3,0.2,0.5h0.1c9.4,20,21.8,37.6,37.6,49.5c19.3,14.6,34.5,23.1,46.5,25.9c18.9,4.4,38.4,7.4,46.7,8.7
									c4.8,0.8,9.6,1.6,14.2,2.4c18.8,3.3,35.1,6.1,44.7,2.7c2,20.1,5.6,33.8,7.4,38.6c0.1,0.2,0.2,0.6,0.4,1.2
									c1.5,4.9,6.2,19.7,21.2,42.1l1.5,2.3c6,9,11.2,16.7,18.1,21.7c7.1,5.1,17.7,7.8,30.6,7.8c1,0,2.1,0,3.1-0.1
									c1,0.1,1.9,0.2,2.9,0.2c0,0,0,0,0.1,0c10.3,0,20.4-5.1,29.9-15.1c7.1-7.4,11.5-15.2,12.7-17.4l0.1-0.2l3-88.3l-29.4-2.4
									l-14.5,15.1c-5.3-14.9-8.2-38.8-8.6-57.5c-0.8-34.5,6.7-58.9,11.7-75.1c3.7-12,9.6-28.1,17.1-36.9c5.8-6.7,10.9-9.8,15.5-12.6
									c5.2-3.1,9.7-5.8,13.7-12.7c4.4-7.5,4.6-13.6,5.3-36.1c0.1-3,0.2-6.3,0.3-10c0.2-7.1,0.5-16.4,0.7-25.4
									c0.3-11.6,0.6-22.5,0.8-26.8h-0.1C884.9,112.7,885.3,106.1,883.2,99.6z M800.4,87.7c1.8-0.2,3.7,0.7,5.2,2.5
									c1.6,1.8,2.6,4.4,2.8,7.2c0.3,2.9-0.3,5.5-1.5,7.6c-1.2,2-2.8,3.2-4.7,3.3c-3.9,0.3-7.5-4-8-9.7S796.5,88,800.4,87.7z
									M782.6,105.6c1.8-0.2,3.7,0.7,5.2,2.5c1.6,1.8,2.6,4.4,2.8,7.2s-0.3,5.5-1.6,7.6c-1.2,2-2.8,3.2-4.7,3.3c-3.9,0.3-7.5-4-8-9.7
									c-0.2-2.8,0.3-5.5,1.6-7.6C779.1,106.9,780.7,105.7,782.6,105.6z M761.5,72.3c1.5-2.1,3.7-3.3,6.1-3.5h0.7
									c2.1,0,4.2,0.9,5.9,2.5c1.9,1.8,3.1,4.3,3.3,7.1c0.4,5.5-3.2,10.5-8.2,10.9c-2.4,0.2-4.7-0.7-6.6-2.4c-1.9-1.8-3.1-4.3-3.3-7.1
									S759.9,74.3,761.5,72.3z M739.2,98c1.5-2.1,3.7-3.3,6.1-3.5h0.7c2.1,0,4.2,0.9,5.9,2.5c1.9,1.8,3.1,4.3,3.3,7.1
									c0.4,5.5-3.3,10.5-8.2,10.9c-5,0.4-9.4-3.9-9.9-9.5C736.9,102.7,737.6,100,739.2,98z M726.2,57.5c0.2,0,0.4,0,0.6,0
									c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.6,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1
									c0.1,0,0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.3s0.4,0.3,0.6,0.4c0.2,0.1,0.3,0.2,0.5,0.3
									c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0.5,1.1,1.1,1.6,1.8c0.2,0.2,0.3,0.4,0.4,0.7l0,0
									c0.1,0.2,0.2,0.5,0.4,0.7c0.1,0.2,0.2,0.4,0.2,0.5c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.5,0.2,0.8c0.2,0.7,0.3,1.4,0.4,2.1
									c0.5,5.8-3.1,10.7-8.1,11.1c-2.4,0.2-4.7-0.7-6.6-2.4c-1.9-1.8-3.1-4.3-3.3-7.1C717.6,62.9,721.3,58,726.2,57.5z M637.3,64.3
									c0.4-3,1.8-5.7,4.1-7.6c1.8-1.4,1.8-1.4,1.8-1.4v0.1c1.6-0.9,3.5-1.4,5.4-1.4c0.5,0,0.9,0,1.4,0.1c3,0.4,5.7,1.9,7.5,4.4
									c1.4,1.9,2.2,4.1,2.3,6.4l0,0c0,0,0.2,1.5,0,2.5c-0.5,2.9-2,5.6-4.4,7.5s-5.4,2.8-8.3,2.4c-3-0.4-5.7-1.9-7.5-4.4
									C637.7,70.4,636.9,67.4,637.3,64.3z M598,192.8c-0.8,6.4-6.5,11-12.7,10.3c-6.2-0.8-10.6-6.6-9.8-13c0.1-0.6,0.2-1.2,0.4-1.8
									l0.7-2.3c0.9-1.8,2.3-3.3,4-4.4c0.2-0.1,0.4-0.3,0.7-0.4c0.3-0.2,0.5-0.3,0.8-0.4s0.5-0.2,0.8-0.4c0.6-0.2,1.1-0.4,1.7-0.5
									c0.5-0.1,1-0.2,1.5-0.2c0.2,0,0.5,0,0.7,0s0.4,0,0.7,0c0.1,0,0.2,0,0.3,0s0.3,0,0.4,0.1c3.8,0.5,6.9,2.8,8.5,6.1
									c0,0,0,0.1-0.1,0.1l0,0l1,2.2C598,189.7,598.2,191.2,598,192.8z M663.3,361.8c0,0.1,0,0.2,0,0.4c0,0.2,0,0.3,0,0.5
									c0,0.3-0.1,0.5-0.1,0.8l0,0c0,0,0,0,0,0.1s-0.1,0.2-0.1,0.4c-0.1,0.3-0.2,0.8-0.4,1.3c-1.8,4.7-6.3,7.7-11.1,7.1
									c-2.8-0.3-5.3-1.8-7-4.2c-1.8-2.2-2.6-5.2-2.2-8.2c0.6-4.7,3.9-8.4,8-9.6l2-0.2h0.6c0.4,0,0.8,0,1.2,0.1c2.8,0.3,5.3,1.8,7,4.2
									c1.2,1.7,2,3.8,2.2,5.9l0,0v0.1C663.3,360.9,663.3,361.3,663.3,361.8z M711.9,369.6c-0.1,0.7-0.3,1.5-0.5,2.2l0,0l-0.8,1.9
									c-2,3.9-6.2,6.4-10.6,5.9c-2.8-0.3-5.3-1.8-7-4.2c-1.6-2.5-2.3-5.4-2-8.4c0.7-5.7,5.3-9.9,10.6-9.9c0.4,0,0.8,0,1.2,0.1
									c0.2,0,0.4,0.1,0.6,0.1l1.3,0.3c0.2,0,0.4,0.1,0.6,0.2h0.1l0,0h0.1l0,0l0,0c0.8,0.3,1.7,0.8,2.4,1.4c0.4,0.3,0.7,0.6,1.1,1
									c0.3,0.3,0.6,0.7,0.9,1.1C711.5,363.7,712.3,366.6,711.9,369.6z M726.7,351.8c-0.2,0.3-0.4,0.6-0.6,0.8
									c-0.2,0.3-0.4,0.5-0.6,0.7c-0.2,0.2-0.3,0.3-0.5,0.5s-0.4,0.3-0.5,0.4c-0.9,0.7-2,1.1-3.1,1.3c-0.2,0-0.4,0.1-0.6,0.1
									c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.8,0c-0.2,0-0.5,0-0.7-0.1c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1
									c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2s-0.4-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.6-0.4
									c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.4-0.4-0.6-0.5c-0.3-0.3-0.5-0.6-0.8-0.9
									c-0.2-0.3-0.4-0.5-0.5-0.8c-0.2-0.3-0.4-0.6-0.6-1c-0.2-0.3-0.3-0.6-0.5-1s-0.3-0.8-0.5-1.3c-0.1-0.3-0.2-0.6-0.3-1
									c0-0.2-0.1-0.4-0.1-0.6s-0.1-0.4-0.1-0.6c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.5-0.1-0.7c-0.1-0.8-0.1-1.6,0-2.3c0-0.1,0-0.2,0-0.2
									c0-0.2,0-0.5,0.1-0.8c0-0.1,0-0.2,0-0.4v-0.1c0-0.2,0.1-0.5,0.1-0.7l0,0c0.1-0.3,0.1-0.6,0.2-0.9c0-0.1,0.1-0.3,0.1-0.4
									c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0-0.1
									c0,0,0-0.1,0.1-0.1c0.1-0.2,0.3-0.5,0.4-0.7c1.5-2.3,3.6-3.7,6-3.9h0.6c2.2,0,4.3,1,6,2.9c1.9,2.1,3.2,5,3.4,8.2
									C729,346.3,728.3,349.4,726.7,351.8z M807.4,162.9c-2.1,2.8-4.6,6.2-8.8,11.3c-0.6,0.8-1.2,1.5-1.8,2.3s-1.2,1.5-1.8,2.3
									c-36.5,47.9-47.9,113.9-49.8,127c-0.2,1.2-0.4,2.4-0.6,3.7c-1.1-1.8-2.6-3.4-4.5-4.8c-3.9-2.8-16.2-4.6-28.4-6.2l-2.6-0.3
									c-23.2-3.1-27.5-3.8-38.8-5.8l-2.9-0.5c-8.6-1.5-15.7-4.5-18-6c-7.6-4.9-13.2-15.5-15.9-20.7c-0.3-0.6-0.6-1.2-0.8-1.6
									c-9.1-16.6-12.4-46.3-12.4-68.6c0-23.2,12.9-63.8,33.1-74.5c8.4-4.5,40.3-0.5,85.4,10.5c34.4,8.4,63.9,17.8,73,22.3
									c1.1,0.6,1.8,1,2,1.3l0.1-0.1c0.1,0.1,0.1,0.1,0.2,0.2C811.4,157.4,809.7,159.7,807.4,162.9z M824,143.6
									c-2.6,0.2-5.2-3.3-5.5-7.8s1.5-8.4,4.1-8.6c2.6-0.2,5.2,3.3,5.5,7.8S826.6,143.4,824,143.6z M841.8,131.3
									c-2.3,0.2-4.4-2.7-4.7-6.4c-0.2-1.9,0.2-3.7,0.9-5.1c0.7-1.2,1.6-2,2.7-2.1c0.1,0,0.1,0,0.2,0c1,0,1.9,0.6,2.8,1.6
									c1,1.2,1.6,2.9,1.8,4.8C845.7,127.8,844.1,131.1,841.8,131.3z"/>
							</g>
						</g>
						<g>
							<path className="st0" d="M883.2,99.6C876.7,79,847,65,825.3,54.7c-23.4-11.1-58.7-20.5-70.3-23.2c-29.1-6.8-50.7-11.5-83.8-15.3
								c-11.6-1.3-21.4-2.1-33.4,0.9c-45,11.5-72.3,52-82,68.9c-32.6,57-29.8,117.8-29.4,124.5c2,32.4,9.5,70.2,24.3,101.8
								c0.1,0.2,0.1,0.3,0.2,0.5h0.1c9.4,20,21.8,37.6,37.6,49.5c19.3,14.6,34.5,23.1,46.5,25.9c18.9,4.4,38.4,7.4,46.7,8.7
								c4.8,0.8,9.6,1.6,14.2,2.4c18.8,3.3,35.1,6.1,44.7,2.7c2,20.1,5.6,33.8,7.4,38.6c0.1,0.2,0.2,0.6,0.4,1.2
								c1.5,4.9,6.2,19.7,21.2,42.1l1.5,2.3c6,9,11.2,16.7,18.1,21.7c7.1,5.1,17.7,7.8,30.6,7.8c1,0,2.1,0,3.1-0.1
								c1,0.1,1.9,0.2,2.9,0.2c0,0,0,0,0.1,0c10.3,0,20.4-5.1,29.9-15.1c7.1-7.4,11.5-15.2,12.7-17.4l0.1-0.2l3-88.3l-29.4-2.4
								l-14.5,15.1c-5.3-14.9-8.2-38.8-8.6-57.5c-0.8-34.5,6.7-58.9,11.7-75.1c3.7-12,9.6-28.1,17.1-36.9c5.8-6.7,10.9-9.8,15.5-12.6
								c5.2-3.1,9.7-5.8,13.7-12.7c4.4-7.5,4.6-13.6,5.3-36.1c0.1-3,0.2-6.3,0.3-10c0.2-7.1,0.5-16.4,0.7-25.4
								c0.3-11.6,0.6-22.5,0.8-26.8h-0.1C884.9,112.7,885.3,106.1,883.2,99.6z M671,18.3c33,3.8,54.5,8.5,83.6,15.3
								c11.5,2.7,46.6,12,69.9,23c9.8,4.6,21.3,10.1,31.4,16.5L836,74c-6.9-4.3-14.5-7.8-21.9-11.1c-27.4-12.1-49.8-18.5-54-19.6
								c-37.3-10.2-74.9-16.6-89.6-17.9l-1.3-0.1c-1.9-0.2-3.7-0.3-5.6-0.5l6.7-6.6C670.5,18.2,670.8,18.3,671,18.3z M851.8,112.7
								c-1-10.8-8.3-20.4-22.6-29.7l6.7-6.6c9.3,5.8,16.5,12.6,19.4,20.9c1.4,4.1,2.1,11.4,0.4,18.4c-0.6,2.5-2.1,5-4.2,7.4
								C852.3,120.5,852.3,117.3,851.8,112.7z M827.5,81.9c-1.3-0.8-2.5-1.6-3.9-2.4c-20.4-11.8-48.4-20.8-68.2-26.2
								c-21.7-6-44.9-11.2-63.7-14.5c-11.6-2-21.7-3.3-30-3.8v-8.3c2.4,0.2,4.8,0.4,7.3,0.6l1.3,0.1c14.7,1.3,52.1,7.7,89.3,17.9
								c4.2,1.1,26.4,7.4,53.7,19.5c7.3,3.2,14.5,6.7,20.9,10.5L827.5,81.9z M528.5,210.6c-0.4-6.7-3.1-66.9,29.1-123.4
								c28.6-50,66-64.2,80.7-68c10.5-2.7,19.3-2.3,29.4-1.2l-6.8,6.7c-16.5-1.1-32,0.5-58.7,19.5c-9.9,7-19.3,15.5-25.2,22.6
								c-24.1,29.4-33.7,56.8-37.2,70.5c-12.8,50-13.1,91.1,0.5,141.7C533.6,256,529.9,232.1,528.5,210.6z M635.5,386.3
								c-11.6-2.6-26.6-10.9-45.7-25.4c-15.6-11.8-27.8-29.1-37.1-49c-23.4-66.8-26.4-114.1-10.9-174.2c3.5-13.6,12.9-40.7,36.8-69.8
								c2.1-2.6,10.3-11.9,24.8-22.2c25.5-18.1,40.5-20,56.2-19.2v8.4c-9-0.5-15.9,0-20.8,1.3c-45.3,12.6-75.7,58.9-89.1,98.1
								c-12,35-10.2,74.9-9.2,96.4v0.6c1,21.5,7,75.6,42.3,113.6c11.6,12.5,39.8,32.7,57.5,35.8c1.1,0.2,2.2,0.4,3.3,0.6l0.3,6.9
								C641.1,387.6,638.3,387,635.5,386.3z M696.6,397.5l-14.4-2.4c-6.8-1.1-20.9-3.3-36.1-6.4l-0.3-7c11.6,2.1,19.8,3.6,26.6,4.8
								c10.8,2,18,3.3,29.7,5.2l1.6,0.3c5.2,0.9,12.2,2,19,2.5l0.3,6.9C715.1,400.8,706,399.2,696.6,397.5z M724.9,401.5l-0.3-6.9
								c1.3,0.1,2.6,0.1,3.9,0.1c4.4,0,8.5-0.4,11.6-1.6c0.2,2.4,0.3,4.8,0.5,7C736.7,401.6,731.3,401.9,724.9,401.5z M739.4,368.5
								c-0.2,8,0,15.5,0.4,22.4c-8.4,3.6-25.6,0.8-36-0.9l-1.6-0.3c-11.7-1.9-18.8-3.2-29.6-5.2c-7.8-1.4-17.4-3.2-31.9-5.8
								c-17.3-3.1-44.9-22.9-56.4-35.2c-34.8-37.5-40.8-91-41.8-112.3v-0.6c-1-21.3-2.8-60.9,9.1-95.6c20.2-59,58.5-88.7,87.7-96.8
								c19.7-5.5,74.5,5.7,115.5,17c19.7,5.4,47.5,14.4,67.8,26c17.5,10.1,26.1,20.1,27.2,31.6c0.7,7.1,0.6,10.3-2.5,14
								c0.1-0.9,0.2-1.9,0.1-2.9c-0.2-2.3-1-4.4-2.2-5.9c-1.3-1.7-3-2.5-4.7-2.3c-1.7,0.1-3.2,1.2-4.3,3.1c-0.9,1.7-1.4,3.9-1.2,6.2
								c0.3,4.1,2.5,7.3,5.2,8.1c-1.9,1.4-3.8,2.8-5.7,4.3c-1.7,1.3-3.6,2.6-5.4,4c0.8-1.8,1.2-4.1,1-6.5c-0.5-5.7-3.9-9.9-7.7-9.6
								c-3.8,0.3-6.5,5.1-6,10.8c0.5,5.5,3.6,9.6,7.3,9.6c-1.7,1.4-3.4,2.9-5,4.3c-3.6-3.4-12.7-6.5-23.1-10c-4-1.3-8.1-2.7-12.3-4.3
								c-22.6-8.3-55.4-16.7-60.8-17.9c-4.2-0.9-8.1-1.8-11.9-2.7c-35.3-8-53.1-12-69.2-3.4c-9.4,5-15,14.2-18.1,19.2l-0.5,0.9
								c-12.1,19.6-17,49.4-17.5,68.9c-0.3,13,0.8,28.6,3.2,42.7c3,17.6,7.7,32.2,13.6,42.3c6.7,11.4,10.6,16.2,20.7,22.3
								c3.9,2.3,15,4.2,30.3,6.7c5.1,0.9,10.5,1.7,16.1,2.7c6,1.1,10.7,1.8,14.9,2.4c5.9,0.9,10.5,1.6,15.6,2.8
								c11.6,2.8,18.2,6.7,21.7,12.6C740.3,346.9,739.6,358,739.4,368.5z M841.8,131.3c-2.3,0.2-4.4-2.7-4.7-6.4
								c-0.2-1.9,0.2-3.7,0.9-5.1c0.7-1.2,1.6-2,2.7-2.1c0.1,0,0.1,0,0.2,0c1,0,1.9,0.6,2.8,1.6c1,1.2,1.6,2.9,1.8,4.8
								C845.7,127.8,844.1,131.1,841.8,131.3z M824,143.6c-2.6,0.2-5.2-3.3-5.5-7.8s1.5-8.4,4.1-8.6c2.6-0.2,5.2,3.3,5.5,7.8
								S826.6,143.4,824,143.6z M745.1,305.6c-0.2,1.3-0.4,2.5-0.5,3.8c-1.1-1.8-2.6-3.4-4.5-4.8c-3.9-2.8-16.2-4.6-28.4-6.2l-2.6-0.3
								c-23.2-3.1-27.5-3.8-38.8-5.8l-2.9-0.5c-8.6-1.5-15.7-4.5-18-6c-7.6-4.9-13.2-15.5-15.9-20.7c-0.3-0.6-0.6-1.2-0.8-1.6
								c-9.1-16.6-12.4-46.3-12.4-68.6c0-23.2,12.9-63.8,33.1-74.5c8.4-4.5,40.3-0.5,85.4,10.5c34.4,8.4,63.9,17.8,73,22.3
								c1.1,0.6,1.8,1,2,1.3l0.1-0.1c0.1,0.1,0.1,0.1,0.2,0.2c-2.7,2.8-4.4,5.1-6.7,8.3c-2.1,2.8-4.6,6.2-8.8,11.3
								c-0.6,0.8-1.2,1.5-1.8,2.3s-1.2,1.5-1.8,2.3C758.4,226.6,747,292.5,745.1,305.6z M646.2,294.8c-10.2-6.6-14.3-15.6-16.4-20.4
								c-0.4-0.9-0.8-1.7-1.1-2.3c-9.9-18.9-12.5-56.3-12.5-75.4c0-12.4,3.4-29.1,8.9-43.3c6.5-16.9,15.3-29.1,24.8-34.3
								c1.3-0.7,3.3-1.3,5.8-1.6c-1.2,0.3-2.3,0.7-3.2,1.1c-9.7,5.1-18.7,17.2-25.3,34c-5.5,14-8.9,30.2-8.9,42.2
								c0,22.6,3.3,52.7,12.6,69.6c0.2,0.4,0.5,0.9,0.8,1.5c2.8,5.3,8.6,16.2,16.6,21.4c1.4,0.9,4.1,2.2,7.7,3.4l-5.1,6.5
								C648.8,296.3,647.1,295.5,646.2,294.8z M650,298.9l-1,9.2c-2.4-0.6-4.1-1.2-5.2-1.9c-9.8-5.9-13.4-10.3-20-21.6
								c-8.6-14.7-17.5-47.3-16.6-84c0.5-19.3,5.3-48.7,17.2-67.9l0.5-0.9c3-4.8,8.4-13.8,17.3-18.5c4.8-2.3,9.7-3.7,15.1-4.2l0.2,6.1
								c-3.7,0.3-6.7,0.9-8.7,2c-21.5,11.9-34.7,54.3-34.7,79.4c0,12.6,1,26.9,2.7,39.3c2.3,16.2,5.7,28.7,10,37.1
								c0.3,0.6,0.6,1.3,1,2.2c2.2,5,6.4,14.3,17.2,21.2C646.2,297.2,647.9,298,650,298.9z M652,299.8c3.9,1.5,8.6,2.9,13.5,3.7
								c3.1,0.6,5.9,1.1,8.6,1.6c8.8,1.6,16.4,3,33.2,5.3c2.6,0.3,5,0.7,7.4,0.9c5.3,0.7,10.3,1.3,14.7,2.2c0.4,0.1,0.8,0.2,1.2,0.3
								l-1.5,9.6c-2.6-1-5.4-1.9-8.7-2.6c-5.2-1.2-9.8-2-15.8-2.8c-4.1-0.6-8.8-1.3-14.8-2.4c-5.7-1-11-1.9-16.2-2.7
								c-9.1-1.5-17-2.8-22.5-4.1L652,299.8z M729.7,311.7c-4.5-0.9-9.5-1.6-14.8-2.2c-2.4-0.3-4.8-0.6-7.4-0.9
								c-16.7-2.3-24.3-3.7-33.1-5.3c-2.7-0.5-5.4-1-8.6-1.6c-5-0.9-9.5-2.3-13.1-3.6l5.1-6.5c2.7,0.8,5.8,1.6,9.1,2.2l2.9,0.5
								c11.4,2,15.6,2.7,38.9,5.8l2.6,0.3c7.5,1,21,2.7,26.2,5l-5.8,6.7C731.1,311.9,730.4,311.8,729.7,311.7z M732.4,314.4
								c1.9,0.6,3.9,1.6,6.7,4c0.9,0.8,2.1,2.6,3,3.9c0.3,0.4,0.5,0.8,0.7,1.1c-0.4,2.9-0.7,5.9-1,8.8c-2.5-3.3-6.1-5.9-11-8
								L732.4,314.4z M740.4,316.8c-2.6-2.3-4.6-3.4-6.6-4.2l5.6-6.2c2.3,1.8,4,4.2,4.7,6.6c-0.3,2.5-0.6,4.9-0.9,7.3
								C742.3,319,741.3,317.6,740.4,316.8z M812.1,151.2c-10.6-5.3-44.2-15.5-73.7-22.9c-22.8-5.7-59.3-13.9-79-13.1l-0.2-6.2
								c12.3-0.6,27.8,2.8,50.9,8.1c3.7,0.8,7.7,1.7,11.9,2.7c5.3,1.2,38,9.6,60.5,17.8c4.2,1.5,8.3,2.9,12.3,4.3
								c10.2,3.4,19,6.4,22.3,9.5l-1.7,1.7C814.7,152.6,813.6,151.9,812.1,151.2z M790.6,506.3c-6.6-4.8-11.7-12.4-17.6-21.2l-1.5-2.3
								c-14.9-22.1-19.5-36.8-21-41.6c-0.2-0.6-0.3-1-0.4-1.3c-2.6-7.1-9.3-33.6-8.5-71.5c0.4-19,2.2-40.1,5.6-62.7
								c2-13.4,13.9-82.8,52.9-130.5c4.2-5.1,6.8-8.6,8.9-11.4c3.2-4.3,5.2-6.9,10-11.6c3.9-3.7,8.3-7.2,12.8-10.5l18.4,6.5
								c-3.4,2.6-9.1,7-18.1,14.8c-3.7,3.2-36.7,33.6-59.2,108c-5.6,18.6-16.1,53.3-16,99.6c0,25.9,0.1,65.1,22.6,103.5
								c8.4,14.3,19.3,33,35.3,39.3C804.6,513,796.4,510.5,790.6,506.3z M843.4,394.6l27.1,2.2l-0.4,0.5c-3.3,4.2-7,8.1-11,11.6
								c-7.8,6.8-15.2,13.3-20.4,12.6c-3.9-0.5-7.1-5-9.8-11.9L843.4,394.6z M881.7,141.4c-0.2,9-0.5,18.2-0.7,25.3
								c-0.1,3.7-0.2,7-0.3,10c-0.7,22.6-0.8,28-5,35.1c-3.8,6.4-8,9-13,12c-4.7,2.8-10,6-15.9,13c-6.2,7.2-12.1,19.9-17.5,37.6
								c-5,16.3-12.6,41-11.8,75.8c0.4,15.6,2.3,32.7,5.2,45.6c3.9,17.5,9.2,26.9,15.8,27.7c6.1,0.8,13.5-5.6,22-13.1
								c3.4-2.9,6.5-6.1,9.4-9.6L868,458c-0.5-3.7-2-6.7-4.3-6.9h-0.2c-3.5,0-5.9,6.1-6.4,12.1c-0.3,3.2,0,6.3,0.7,8.7
								c0.8,2.7,2.2,4.2,3.8,4.4c0.1,0,0.2,0,0.2,0c2.8,0,4.9-4,5.8-8.7l-0.5,15c-1.3,2.4-5.6,9.7-12.3,16.7
								c-9.2,9.6-18.7,14.5-28.5,14.5c0,0,0,0-0.1,0c-21.3,0-35-23.5-45.1-40.6c-22.2-37.8-22.3-76.6-22.3-102.4
								c-0.1-46,10.4-80.5,16-99.1c22.3-73.8,54.9-103.9,58.6-107.1c11.3-9.9,17.4-14.3,20.3-16.4c0.5-0.3,0.8-0.6,1.1-0.8
								c3.8-2.8,6.3-4.7,8.2-6.1c4.2-3,5.6-4.1,10.6-8.5c3.9-3.4,6.7-7.1,8.6-11.2C882,127,881.9,134.1,881.7,141.4z M866,464
								c-0.5,6.4-3,10.4-4.4,10.3c-0.7-0.1-1.5-1.2-2.1-2.9c-0.6-2.1-0.9-4.9-0.6-7.9c0.5-6.3,2.9-10.3,4.4-10.3c0,0,0,0,0.1,0
								C864.8,453.3,866.5,457.6,866,464z M882.5,114.2c-1.2,6.6-4.5,12.2-10.2,17.1c-5,4.3-6.4,5.3-10.5,8.4c-1.9,1.4-4.5,3.3-8.3,6.1
								c-0.3,0.2-0.7,0.5-1.1,0.8c-0.1,0.1-0.2,0.2-0.4,0.3l-18.4-6.5c0.7-0.5,1.4-1,2-1.5c1.5-1.1,2.9-2.1,4.3-3.2l0.1,0.1
								c1.3-1,2.4-1.9,3.5-2.8c7-5.5,12.7-10.9,14.2-17c1.9-7.9,0.9-15.5-0.5-19.5c-2.5-7.2-8.1-13.8-17.2-20.1
								c-0.4-0.3-0.8-0.5-1.1-0.8l19.9-0.9c10.7,7.1,19.4,15.5,22.5,25.3C883.6,107.5,882.5,114,882.5,114.2z"/>
							<path className="st0" d="M659.1,57.3c-2.2-3-5.4-4.8-8.9-5.2s-7,0.6-9.8,2.8c-2.8,2.3-4.6,5.5-5,9.1c-0.4,3.6,0.5,7.2,2.7,10.1
								c2.2,2.9,5.3,4.7,8.9,5.2c0.5,0.1,1.1,0.1,1.6,0.1c3,0,5.8-1,8.2-2.9c2.8-2.3,4.6-5.5,5-9.1C662.2,63.8,661.3,60.2,659.1,57.3z
								M659.8,67.4c-0.5,2.9-2,5.6-4.4,7.5s-5.4,2.8-8.3,2.4c-3-0.4-5.7-1.9-7.5-4.4c-1.9-2.5-2.7-5.5-2.3-8.6c0.4-3,1.8-5.7,4.1-7.6
								c1.8-1.4,1.8-1.4,1.8-1.4c1.6-0.9,3.5-1.4,5.4-1.4C649,54,649.5,54,650,54c3,0.4,5.7,1.9,7.5,4.4c1.4,1.9,2.2,4.2,2.3,6.5
								C659.8,64.9,660,66.3,659.8,67.4z"/>
							<path className="st0" d="M586.9,191.8h0.2c0.2,0,0.4,0,0.6,0C587.4,191.8,587.1,191.8,586.9,191.8z M586.9,191.8h0.2
								c0.2,0,0.4,0,0.6,0C587.4,191.8,587.1,191.8,586.9,191.8z M588.4,177.8c-7.3-0.9-14,4.5-14.9,12s4.2,14.4,11.5,15.3
								c0.5,0.1,1.1,0.1,1.6,0.1c6.6,0,12.4-5.1,13.3-12.1C600.9,185.6,595.7,178.7,588.4,177.8z M598,192.8c-0.8,6.4-6.5,11-12.7,10.3
								c-6.2-0.8-10.6-6.6-9.8-13c0.1-0.6,0.2-1.2,0.4-1.8l0.7-2.3c2-3.6,5.6-6,9.5-6.3c0.2,0,0.5,0,0.7,0s0.4,0,0.7,0
								c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0.1c1.9,0.2,3.6,0.9,5.1,2c1.4,1,2.6,2.4,3.4,4.1v0.1l1,2.2C598,189.7,598.2,191.2,598,192.8z
								M587.1,191.8c0.2,0,0.4,0,0.6,0c-0.3,0-0.5,0-0.8,0H587.1z"/>
							<path className="st0" d="M662.7,353.1c-2-2.8-5-4.5-8.3-5c-6.8-0.9-13.2,4.4-14.1,11.6c-0.4,3.5,0.4,7,2.5,9.7c2,2.8,5,4.5,8.3,5
								c0.5,0.1,1,0.1,1.5,0.1c6.3,0,11.8-5,12.6-11.7C665.6,359.3,664.8,355.8,662.7,353.1z M663.3,361.8c0,0.2,0,0.5-0.1,0.8
								c0,0.3-0.1,0.5-0.1,0.8c-0.7-0.1-1.4-0.3-2-0.5c0.7,0.3,1.3,0.5,2,0.6c-0.1,0.2-0.2,0.9-0.5,1.7c-1.8,4.7-6.3,7.7-11.1,7.1
								c-2.8-0.3-5.3-1.8-7-4.2c-1.8-2.2-2.6-5.2-2.2-8.2c0.6-4.7,3.9-8.4,8-9.6l2-0.2h0.6c0.4-0.1,0.8-0.1,1.2,0c2.8,0.3,5.3,1.8,7,4.2
								c1.2,1.8,2,3.8,2.2,6l0,0v0.1C663.3,360.9,663.3,361.3,663.3,361.8z"/>
							<path className="st0" d="M711.5,360.1c-2-2.8-5-4.5-8.3-5c-6.9-0.9-13.2,4.4-14.1,11.6c-0.4,3.5,0.4,7,2.5,9.7c2,2.9,4.9,4.6,8.3,5
								c0.5,0.1,1,0.1,1.5,0.1c6.3,0,11.8-5,12.6-11.7C714.4,366.3,713.6,362.8,711.5,360.1z M711.9,369.6c-0.1,0.7-0.3,1.5-0.5,2.2l0,0
								l-0.8,1.9c-2,3.9-6.2,6.4-10.6,5.9c-2.8-0.3-5.3-1.8-7-4.2c-1.6-2.5-2.3-5.4-2-8.4c0.7-5.7,5.3-9.9,10.6-9.9c0.4,0,0.8,0,1.2,0.1
								c0.2,0,0.4,0.1,0.6,0.1l1.8,0.5h0.1l0,0h0.1c0.9,0.3,1.7,0.8,2.4,1.4c0.4,0.3,0.7,0.6,1.1,1c0.3,0.3,0.6,0.7,0.9,1.1
								C711.5,363.7,712.3,366.6,711.9,369.6z"/>
							<path className="st0" d="M712.7,354.1c2.1,2.3,4.7,3.5,7.4,3.5c0.3,0,0.5,0,0.8,0c3-0.3,5.7-2,7.5-4.8s2.6-6.3,2.3-9.9s-1.7-7-4-9.4
								c-2.3-2.5-5.2-3.7-8.2-3.5l0,0c-3,0.3-5.7,2-7.5,4.8s-2.6,6.3-2.3,9.9C709,348.4,710.4,351.7,712.7,354.1z M712.7,335.9
								c1.5-2.3,3.6-3.7,6-3.9l0,0c0.2,0,0.4,0,0.6,0c2.2,0,4.3,1,6,2.9c1.9,2.1,3.2,5,3.4,8.2c0.3,3.2-0.4,6.3-2,8.7
								c-1.5,2.3-3.6,3.7-6,3.9c-2.3,0.2-4.7-0.8-6.6-2.8c-1.9-2.1-3.2-5-3.4-8.2C710.4,341.4,711.1,338.3,712.7,335.9z"/>
							<path className="st0" d="M753.2,95.4c-2.3-2.2-5.2-3.2-8.1-3l0,0c-3,0.3-5.6,1.8-7.5,4.3s-2.7,5.7-2.4,8.9
								c0.5,6.5,5.5,11.4,11.2,11.4c0.3,0,0.6,0,0.8,0c6.1-0.5,10.5-6.4,10-13.2C756.8,100.5,755.4,97.5,753.2,95.4z M747,115
								c-5,0.4-9.4-3.9-9.9-9.5c-0.2-2.8,0.5-5.5,2.1-7.5c1.5-2.1,3.7-3.3,6.1-3.5l0,0c0.2,0,0.5,0,0.7,0c2.1,0,4.2,0.9,5.9,2.5
								c1.9,1.8,3.1,4.3,3.3,7.1C755.6,109.6,751.9,114.6,747,115z"/>
							<path className="st0" d="M738.1,66.9c-0.6-6.8-6-11.9-12-11.4l0,0c-6.1,0.5-10.5,6.4-10,13.2c0.3,3.3,1.7,6.2,3.9,8.4
								c2.1,2,4.6,3,7.3,3c0.3,0,0.6,0,0.9,0C734.2,79.6,738.7,73.7,738.1,66.9z M728,78.1c-2.4,0.2-4.7-0.7-6.6-2.4
								c-1.9-1.8-3.1-4.3-3.3-7.1c-0.5-5.7,3.2-10.6,8.1-11.1l0,0c5-0.4,9.4,3.9,9.9,9.5C736.6,72.8,733,77.7,728,78.1z"/>
							<path className="st0" d="M779.4,78.1c-0.3-3.3-1.7-6.2-3.9-8.4c-2.3-2.2-5.2-3.2-8.1-3c-3,0.3-5.6,1.8-7.5,4.3s-2.7,5.7-2.4,8.9
								c0.3,3.3,1.7,6.2,3.9,8.4c2.1,2,4.6,3,7.3,3c0.3,0,0.6,0,0.9,0C775.5,90.8,780,84.9,779.4,78.1z M769.3,89.3
								c-2.4,0.2-4.7-0.7-6.6-2.4c-1.9-1.8-3.1-4.3-3.3-7.1s0.5-5.5,2.1-7.5c1.5-2.1,3.7-3.3,6.1-3.5l0,0c0.2,0,0.5,0,0.7,0
								c2.1,0,4.2,0.9,5.9,2.5c1.9,1.8,3.1,4.3,3.3,7.1C777.9,83.9,774.3,88.9,769.3,89.3z"/>
							<path className="st0" d="M807.1,88.9c-1.9-2.3-4.4-3.4-6.9-3.2c-5,0.4-8.6,6.3-8.1,13.1c0.6,6.5,4.8,11.5,9.6,11.5c0.2,0,0.4,0,0.6,0
								c2.5-0.2,4.7-1.7,6.2-4.3c1.5-2.4,2.1-5.6,1.8-8.8C810.2,94.1,809,91.1,807.1,88.9z M806.9,105c-1.2,2-2.8,3.2-4.7,3.3
								c-3.9,0.3-7.5-4-8-9.7s2.3-10.6,6.2-10.9c1.8-0.2,3.7,0.7,5.2,2.5c1.6,1.8,2.6,4.4,2.8,7.2C808.7,100.3,808.1,102.9,806.9,105z"
								/>
							<path className="st0" d="M789.3,106.8c-1.9-2.3-4.4-3.4-6.9-3.2s-4.7,1.7-6.2,4.3c-1.5,2.4-2.1,5.6-1.8,8.8
								c0.6,6.5,4.8,11.5,9.6,11.5c0.2,0,0.4,0,0.6,0c2.5-0.2,4.7-1.7,6.2-4.3c1.5-2.4,2.1-5.6,1.8-8.8
								C792.3,111.9,791.1,108.9,789.3,106.8z M789,122.9c-1.2,2-2.8,3.2-4.7,3.3c-3.9,0.3-7.5-4-8-9.7c-0.2-2.8,0.3-5.5,1.6-7.6
								c1.2-2,2.8-3.2,4.7-3.3c1.8-0.2,3.7,0.7,5.2,2.5c1.6,1.8,2.6,4.4,2.8,7.2C790.8,118.1,790.3,120.8,789,122.9z"/>
							<path className="st0" d="M802.4,320.8L802.4,320.8c-2.1-0.2-4.1,1-5.7,3.3c-1.5,2.1-2.5,5-2.7,8.1c-0.3,3.1,0.2,6.1,1.3,8.4
								c1.2,2.5,3,4,5.1,4.2c0.1,0,0.3,0,0.4,0c4,0,7.5-4.9,8-11.4C809.4,326.7,806.6,321.1,802.4,320.8z M806.9,333.2
								c-0.4,5.4-3.3,9.8-6.3,9.5c-1.4-0.1-2.6-1.2-3.5-3c-1-2-1.4-4.6-1.2-7.4s1.1-5.3,2.4-7.1c1.1-1.6,2.4-2.4,3.7-2.4
								c0.1,0,0.2,0,0.2,0l0,0C805.2,323,807.3,327.8,806.9,333.2z"/>
							<path className="st0" d="M845.9,177.1c-1.9-0.2-3.8,1-5.3,3.3c-1.4,2.1-2.3,5-2.5,8.1c-0.3,3.1,0.2,6.1,1.2,8.4
								c1.1,2.5,2.8,4,4.7,4.2c0.1,0,0.2,0,0.4,0c1.8,0,3.6-1.2,5-3.3c1.4-2.1,2.3-5,2.5-8.1c0.3-3.1-0.2-6.1-1.2-8.4
								C849.5,178.7,847.8,177.2,845.9,177.1z M849.8,189.4c-0.2,2.8-1,5.3-2.2,7.2c-1.1,1.6-2.3,2.5-3.5,2.4s-2.3-1.2-3-3
								c-0.9-2-1.2-4.7-1-7.4s1-5.3,2.2-7.2c1-1.6,2.2-2.4,3.3-2.4c0.1,0,0.1,0,0.2,0l0,0c1.2,0.1,2.3,1.2,3,3
								C849.6,184,850,186.7,849.8,189.4z"/>
							<path className="st0" d="M841.4,491.7c0.1,0,0.3,0,0.4,0c4,0,7.5-4.9,8-11.4c0.6-6.7-2.3-12.2-6.5-12.6l0,0c-2.1-0.2-4.1,1-5.7,3.3
								c-1.5,2.1-2.5,5-2.7,8.1C834.3,485.8,837.2,491.4,841.4,491.7z M836.9,479.3c0.2-2.8,1.1-5.3,2.4-7.1c1.1-1.6,2.4-2.4,3.7-2.4
								c0.1,0,0.2,0,0.2,0l0,0c3,0.2,5.1,5,4.6,10.4c-0.4,5.4-3.3,9.8-6.3,9.5C838.6,489.5,836.5,484.7,836.9,479.3z"/>
						</g>
					</g>
				</g>
				<g id="second-front">
					<g>
						<path className="st1" d="M885.9,111.9l-8.1,0.9c0.3,1.7,0.5,3.6,0.5,5.5c0,1-0.1,2-0.2,3c-1.3,10.9-8.5,17.5-22.8,30.4
							c-0.3,0.3-0.7,0.7-1.1,1l-1.6,1.4c-3,2.7-6.3,5.7-9.8,9.1c-0.1,0.1-12.8,12.4-24.2,25.9c-20.3,24.1-30.9,47.4-35.5,57.3
							c-13.7,30-18.2,54.7-20.9,69.4c-0.4,2.3-0.8,4.5-1.2,6.8l-0.5,3.1c-0.1,0.9-0.3,1.8-0.4,2.7l-0.5,3.4c-2.6,19.4-3.2,36.7-2.5,51.9
							l0.2,3.3c2.4,43.3,14.3,67.9,15.1,69.3c0.7,1.8,7.1,18.4,21.7,34.7c4.2,4.7,9.5,8.4,16.7,11.4c5.1,2.2,10.6,3.2,16.7,3.2
							c0.7,0,1.4,0,2.1,0l0,0c0.8,0.1,1.7,0.1,2.6,0.1c2.3,0,4.6-0.3,6.9-1c9-2.7,16.8-10.6,25-19.1c1.6-1.6,3.2-3.3,4.7-5.1l0.2-0.2
							V480c5.6-70,3.4-71.1,1.6-71.3c-0.8-0.2-1.8-0.4-2.7-0.6c-1-0.2-2-0.4-2.7-0.6l-0.5-0.1l-0.4,0.3c-2.3,1.9-4.6,4.1-7.1,6.3
							c-0.9,0.8-1.8,1.7-2.8,2.5c-5.1,4.6-9.7,7.8-16,11.4c-6.2-6.4-13.1-25.8-16.8-48.8c-0.1-0.5-8.5-54.2,13.3-119
							c2.8-8.3,6-13.4,10.3-18.9c2.5-3.1,4.3-4.8,14.2-14.2l2-1.8c1.6-1.5,3-2.8,4.3-4c8.6-8,10.9-10.2,12.6-13.9c3.2-6.8,3-13,2.9-16.7
							v-0.3c-0.4-14.1,0.7-33.8,3.3-60.2C887.4,122.5,886.5,115.4,885.9,111.9z"/>
						<path className="st0" d="M885.9,111.9l-8.1,0.9c0.3,1.7,0.5,3.6,0.5,5.5c0,1-0.1,1.9-0.2,3c-1.3,10.9-8.5,17.5-22.8,30.4
							c-0.4,0.3-0.7,0.7-1.1,1l-1.6,1.4c-3,2.7-6.3,5.7-9.8,9.1c-0.1,0.1-12.8,12.4-24.2,25.9c-20.3,24.1-30.9,47.4-35.4,57.3
							c-13.7,30-18.2,54.7-20.9,69.4c-0.4,2.3-0.8,4.5-1.2,6.8l-0.5,3.1c-0.1,0.9-0.3,1.8-0.4,2.7l-0.5,3.4c-2.6,19.4-3.1,36.7-2.5,51.9
							l0.2,3.3c2.4,43.3,14.3,67.9,15.1,69.3c0.7,1.8,7.1,18.4,21.7,34.7c4.2,4.7,9.6,8.4,16.7,11.4c5.1,2.2,10.6,3.2,16.7,3.2
							c0.7,0,1.4,0,2.1,0l0,0c0.9,0.1,1.7,0.1,2.6,0.1c2.3,0,4.6-0.3,7-1c9-2.7,16.8-10.6,25-19.1c1.6-1.6,3.2-3.3,4.7-5.1l0.2-0.2V480
							c5.6-70,3.4-71.1,1.6-71.3c-0.8-0.2-1.8-0.4-2.7-0.6c-1-0.2-2-0.4-2.7-0.6l-0.5-0.1l-0.4,0.3c-2.3,1.9-4.6,4.1-7.1,6.3
							c-0.9,0.8-1.9,1.7-2.8,2.5c-5.1,4.6-9.7,7.9-16,11.4c-6.2-6.4-13.1-25.8-16.8-48.8c-0.1-0.5-8.5-54.2,13.4-119
							c2.8-8.3,6-13.4,10.3-18.9c2.5-3.1,4.3-4.8,14.2-14.2l2-1.8c1.6-1.5,3-2.8,4.3-4c8.6-8,10.9-10.2,12.6-13.9c3.2-6.8,3-13,2.9-16.7
							v-0.3c-0.4-14.1,0.7-33.8,3.3-60.2C887.4,122.5,886.5,115.4,885.9,111.9z M855.8,418c0.9-0.8,1.9-1.7,2.8-2.6
							c2.3-2.1,4.5-4.1,6.6-5.9c0.7,0.2,1.5,0.3,2.3,0.5c0.2,0,0.4,0.1,0.6,0.1c-1.2,1.1-3,3-6.1,6.2l-0.3,0.4
							c-3.6,3.8-14.7,13.3-19.1,13.4h-0.1c-0.8,0-1.6-0.3-2.5-0.9C846.3,425.8,850.8,422.6,855.8,418z M879.4,190.4v0.3
							c0.1,2.5,0.2,6.2-0.9,10.5c-0.4,1.7-1,3.5-1.9,5.3c-1.6,3.4-3.6,5.3-12.2,13.3c-1.3,1.2-2.7,2.5-4.3,4l-2,1.8
							c-10.1,9.5-11.9,11.2-14.4,14.4c-4.3,5.5-7.8,10.9-10.7,19.5c-22,65.3-13.5,119.4-13.4,119.9c4.3,26.3,13.5,52.7,22.9,52.7
							c0.1,0,0.1,0,0.2,0c5.9-0.2,18.3-11.8,20.5-14.1l0.3-0.4c2.6-2.7,5.4-5.7,6.7-6.7c0.8,2.9,1.1,16.2-3.1,68.5
							c-1.4,1.6-2.9,3.2-4.4,4.7c-8,8.3-15.7,16.1-24.1,18.6c-9.8,2.9-19.2-1.8-24.3-4.3c-35.6-17.9-43.6-75.3-46.2-94.1
							c-3.2-23.2-2-41.4-1.1-54.7c2.1-31.2,8.3-55,10.8-63.8c10.1-34.6,25.2-63.6,46.1-88.9c16.3-19.7,24.6-28.6,35.5-37.9l1.7-1.4
							c6.3-5.4,12.7-10.8,17.4-16.7c1.4-1.7,2.6-3.5,3.7-5.2C880,159.3,879,177.3,879.4,190.4z M882.8,129.6c-0.9,2.7-2.4,5.4-4.3,7.9
							c-4.7,6.5-11.8,12.6-18.7,18.5l-1.7,1.4c-10.9,9.4-19.3,18.3-35.7,38.1c-21.2,25.5-36.4,54.8-46.5,89.7
							c-2.6,8.9-8.8,32.9-10.9,64.3c-0.9,13.4-2.1,31.8,1.1,55.1c2.7,19.1,10.7,77.3,47.3,95.7c1.4,0.7,3.3,1.6,5.5,2.5
							c-2.5-0.5-4.9-1.2-7.2-2.2c-6.9-2.9-12-6.4-16-10.9c-14.5-16.3-21-33.4-21.3-34.1v-0.1c-1.1-2.1-25.8-53.1-10.1-139.3
							c2.7-14.6,7.2-39.1,20.8-68.9c4.5-9.9,15-33,35.2-56.8c11.3-13.4,23.9-25.6,24.1-25.7c4.6-4.4,8.8-8.2,12.5-11.5
							c11.4-10.3,18.7-16.9,21.8-25.1c0.8-2.1,1.3-4.2,1.6-6.5c0.3-2.5,0.2-4.8,0-7l4.1-0.5C884.7,117.8,884.9,123.5,882.8,129.6z"/>
					</g>
				</g>
				<g id="third-front">
					<g>
						<path className="st1" d="M912.8,396.6v-0.2c-6.5-0.3-12.8-0.6-19.2-0.9c-6.4-0.2-12.2-0.6-18.7-0.9h-0.6l-0.3,0.5
							c-1.3,2.3-2.7,3.9-4.3,5.9c-1.9,2.3-5.1,5.2-7.4,7.1c-4-15.2-5.9-38.2-5.4-58c0.5-24.2,1.8-77.6,29.2-104.4l0.3-133.4
							c0,1.4,0,2.9-0.4,4.4c-1.6,8.7-6.4,15-16.8,21.5c-3.7,2.9-7.3,6.5-11.8,10.5c-3,2.7-5.5,4.9-8.4,7.8l-1.3,1.4
							c-2,2-6.8,5.4-8.7,7.3c-3.7,3.7-6.2,7.4-10,11c-20.6,19.6-37.3,47.6-51.4,87.4c-4.5,12.8-8.2,25.5-10.9,35.8l-0.4,2.2
							c-1.3,5.1-2.5,9.5-3.4,13.6l-0.5,2c-1.5,6.9-2.4,12.1-2.8,14c-2.6,15.7-6,29.9-5.3,50.1c0.1,3.1,0.3,6.6,0.6,10.5l0.2,2.2
							c2.8,34.1,14.2,92.6,58,115.2c12,6.2,30.8,10,39.6,10.6v-0.1c1.9,0.3,3.9,0.4,5.9,0.4h0.7c29.5-0.5,48-32.2,50-35.9l0.1-0.2
							l3.7-87.9L912.8,396.6z"/>
						<path className="st0" d="M911.8,398.5c-3.4,5.3-7.3,10.3-11.8,14.7c-6.8,6.8-17.1,17-24.1,15.9c-5.2-0.8-9.6-8.6-12.8-19.9
							c2.7-2.1,5.2-4.4,7.3-7c1.6-1.9,3.1-4,4.4-6.2c6.3,0.3,12.8,0.5,19,0.7c6.1,0.2,12.4,0.4,18.5,0.7L911.8,398.5z M886.1,243.8
							c-29.4,26.9-30.5,82.6-31,107.4c-0.7,31.7,5.2,77.4,20.6,79.9c7.9,1.3,18.7-9.4,25.8-16.5c3.7-3.7,7.1-7.7,10-12l-3.9,82.1
							c-2.4,4.4-20.2,34.2-48.1,34.6c-26.9,0.5-44.2-26.7-53.5-41.2c-22.9-35.8-21.9-73.5-21.1-103.8c0.8-28.1,7.3-99.8,51.9-159.2
							c11.1-14.8,19.9-26.5,34.8-39.9c5-4.5,10.1-8.2,14.8-11.5v-2.4c-5.1,3.5-10.5,7.5-16,12.4c-15.1,13.5-24,25.3-35.1,40.1
							c-44.9,59.9-51.5,132.1-52.3,160.4c-0.8,30.6-1.9,68.6,21.4,104.9c7.2,11.2,19,29.7,36.3,37.9c-8.8-1.7-19-4.6-26.5-8.5
							c-49-25.3-56.7-96.7-57.7-126.2c-0.7-20,1.8-34.8,4.4-50.4c0.9-5.3,5.9-33.5,17.6-66.6c13.9-39.5,31.3-69.4,51.7-88.7
							c3.8-3.7,7.7-7.5,11.4-11.2c5.1-5.1,10.4-10.4,15.8-15.3l29,6v-2l-27.4-5.7c3.9-3.4,7.5-6.3,10.7-8.9c7.6-6,13.8-10.5,16.7-17.1
							v-9.5c0,1.5-0.1,2.1-0.4,3.8c-1.6,8.7-8.2,13.8-17.5,21.2c-3.7,2.9-7.8,6.3-12.3,10.3c-3,2.7-5.9,5.5-8.8,8.4l-1.5,1.5
							c-2,2-4,3.9-5.9,5.9c-3.7,3.7-7.5,7.5-11.3,11.1c-20.6,19.6-38.1,49.7-52.2,89.5c-4.5,12.8-8,24.8-10.7,35.1l-0.6,2.1
							c-1.3,5.1-2.4,9.7-3.3,13.8l-0.5,2.3c-1.5,6.9-2.3,11.8-2.7,13.7c-2.6,15.7-5.1,30.6-4.4,50.8c0.1,3.1,0.3,6.6,0.6,10.5l0.2,2.2
							c2.8,34.2,14.2,92.6,58,115.2c12,6.2,30.7,10,39.6,10.6v-0.1c1.9,0.3,3.9,0.4,5.9,0.4c0.2,0,0.4,0,0.7,0c29.5-0.5,48-32.2,50-35.9
							l0.1-0.2l4.3-90l-0.1,0.5v-0.2c-6.5-0.3-13.2-0.5-19.6-0.7c-6.4-0.2-13.1-0.4-19.6-0.7h-0.6l-0.3,0.5c-1.3,2.3-2.9,4.5-4.6,6.5
							c-1.9,2.3-4.1,4.3-6.4,6.2c-3.9-15.2-6-36.1-5.5-55.9c0.5-24.2,1.7-77.9,29.1-104.7L886.1,243.8z"/>
					</g>
				</g>
				<g id="fourth-front">
					<g>
						<path className="st1" d="M927.4,113.5v94.6c-0.3,0.9-0.7,1.8-1.1,2.8c-4.7,10.6-17.4,19.5-24.3,24.4c-1.4,1-2.6,1.8-3.4,2.4
							c-24.6,19.2-34.8,86.4-35.7,114c-1.3,40.9,8.7,66.5,17.7,76.1c1.3,1.4,2.7,2.6,4.1,3.3c8.7-4.4,12.5-7.1,19.4-12.8
							c2.6-2.2,5.7-5.4,8.3-8.2c1.2-1.3,2.3-2.5,3.3-3.4l0.3-0.3l0.5,0.1c1.1,0.1,2.8,0.2,4.6,0.3c1.4,0.1,2.8,0.1,4,0.2l0,0l0.1,2.6
							c0.2,7-0.1,11.2-1.9,43.9l-0.4,6.8c-1.2,22.7-1.8,26-2.4,29.3l-0.1,0.7l-0.2,0.2c-5.7,7.3-14.9,14.7-24.8,19.8
							c-9,4.7-17.8,7.1-25.4,7.1c-1.6,0-3.2-0.1-4.7-0.4c-3.1-0.5-6.1-1.4-9-2.7c-9.8-3.5-18.5-8.5-26.1-15.1
							c-10.6-9.2-19.1-22.2-25.2-38.8c-9.4-25.6-13.1-51.8-13.9-74.4l-0.1-2.5c-1.1-34.8,4.2-60.3,4.3-60.7c0.4-2,1.4-6.4,1.9-8.4
							l1.7-7.1c8.7-34.2,21.5-65,38.1-91.7s36.4-48.3,58.9-64.2l1.8-1.3c2.6-1.8,5.3-3.6,8-5.2c0.8-0.5,1.8-1,2.7-1.5
							c5.3-2.9,11.3-6.3,15.6-15.1C925.8,124.6,927.4,119.8,927.4,113.5z"/>
						<path className="st0" d="M924.4,210c-4.5,10.1-16.9,18.8-23.6,23.5c-1.5,1-2.6,1.8-3.4,2.5c-25.4,19.9-35.5,86.4-36.5,115.5
							c-0.8,25.9,3.4,61.8,18.2,77.5c3.2,3.4,6.7,5.3,9.9,5.4c0.1,0,0.1,0,0.2,0c8.5,0,24.7-13.9,24.9-14c1.9-1.8,3.3-3.1,4.6-4.2
							c1.7-1.6,3-2.7,4.6-4.4c0,5.9-0.3,12.7-1.9,41.2l-0.4,6.8c-1.2,22.6-1.8,25.9-2.3,29v0.2c-11.3,14.3-35.7,28.3-52.8,25.7
							c-3-0.7-5.9-1.6-8.7-2.6c-15.6-7-26.9-25.2-33.9-40.4c-9.8-21.2-17.2-48.9-20.3-76.1c-4.9-43.1,4.8-95.5,26.8-143.7
							c21.3-46.7,50.8-83.3,81-100.4l0.4-0.2c3.1-1.8,10.3-5.8,16.2-12.6v-3.2c-6,7.7-14,12.2-17.2,14l-0.4,0.2
							C879.3,167,849.5,203.9,828,251c-22.1,48.5-32,101.2-27,144.7c3.1,27.4,10.6,55.4,20.5,76.7c7.1,15.3,15.2,26.9,24,34.4
							c-4.9-2.7-9.6-5.9-13.8-9.5c-10.4-9-18.7-21.7-24.6-38c-24.2-66-9.8-135.9-9.6-136.6c8.6-40.2,22.5-76.1,41.5-106.5
							c18.8-30.3,41.7-53.8,68-70c0.8-0.5,1.7-1,2.6-1.5c5.3-2.9,11.8-6.6,16.5-15.9c0.5-1,1-2.2,1.4-3.4v-12c0,6.4-1.6,11.2-3.2,14.5
							C920,136.7,914,140,908.7,143c-0.9,0.5-1.8,1-2.7,1.5c-2.7,1.7-5.4,3.4-8,5.2l-1.8,1.3c-22.5,15.9-42.2,37.5-58.9,64.2
							c-16.6,26.7-29.4,57.5-38.1,91.7l-1.7,7.1c-0.5,2-1.5,6.4-1.9,8.4c-0.1,0.4-5.4,26-4.3,60.7l0.1,2.5c0.9,22.6,4.5,48.8,13.9,74.4
							c6.1,16.6,14.6,29.6,25.2,38.8c7.6,6.6,16.4,11.6,26.1,15.1c2.9,1.3,5.9,2.2,9,2.7c1.5,0.2,3.1,0.4,4.7,0.4
							c7.5,0,16.4-2.5,25.4-7.1c9.8-5.1,19.1-12.5,24.8-19.8l0.2-0.2l0.1-0.7c0.5-3.2,1.1-6.6,2.4-29.3l0.4-6.8
							c1.8-32.6,2-36.8,1.9-43.9l-0.1-2.6l0,0l0,0c-1.3-0.1-2.7-0.1-4-0.2c-1.8-0.1-3.5-0.2-4.6-0.3h-0.5l-0.3,0.3c-1,1-2.1,2.2-3.3,3.4
							c-2.7,2.9-5.7,6.1-8.4,8.3c-6.9,5.7-10.7,8.4-19.4,12.8c-1.4-0.8-2.7-1.9-4.1-3.3c-9-9.5-19-35.2-17.7-76.1
							c0.9-27.7,11.2-94.8,35.7-114c0.8-0.6,2-1.5,3.4-2.4c6.8-4.8,19.5-13.8,24.3-24.4c0.4-0.9,0.8-1.8,1.1-2.8v-6.7
							C926.7,204.2,925.8,207.1,924.4,210z M905.3,419.8c2.7-2.2,5.8-5.5,8.5-8.4c1.1-1.1,2.1-2.2,2.9-3.1c1.1,0.1,2.6,0.2,4.2,0.2
							c0.8,0,1.7,0.1,2.5,0.1l0,0c-2.3,2.6-3.7,3.9-6.1,6.1c-1.2,1.1-2.7,2.4-4.6,4.3c-2.4,2.3-16.8,13.5-23.5,13.5c-0.1,0-0.1,0-0.2,0
							c-0.7,0-1.4-0.1-2.1-0.4C894.9,428.1,898.7,425.3,905.3,419.8z"/>
					</g>
				</g>
			</svg>
			<Callout
        image={electrode}
				imageHeight={electrodeHeight}
				imageWidth={electrodeWidth}
        body={electrodeBody}
      />
		</section>
  );
})