import React, { useContext } from 'react';
import { ScrollToTop } from 'utils/ScrollToTop';
import {toPlainText} from '@portabletext/react'
import { formatDate, showIfCurrent } from 'utils/blogFunctions'; 

// Sections
import { Loader } from 'components/Loader';

// Style
import './index.scss'
import { BlogContext } from 'contexts/BlogContext';
import { Footer } from 'components/Footer';

export const Blog = () => {
  const {blogPosts, loaded} = useContext(BlogContext);
  const filteredBlogPosts = showIfCurrent(blogPosts)

  return ( !loaded ?
    <Loader /> 
    :
    <div className={`blog-page`} >
      <ScrollToTop />
      <div className='blog-list'>
        <h1 className='blog-list-header'>Blog</h1>
        {blogPosts && filteredBlogPosts.map((blogPost,i) => {
          const formattedDate = formatDate(blogPost.creationDate)

          return(
            <div className="blog-link" key={i}>
              <a href={`/blog/${blogPost.slug}`}><img src={blogPost.image} className="blog-thumb" alt="blog-thumb"></img></a>
              <div className='blogpost-description'>
                <a href={`/blog/${blogPost.slug}`}><h2 className="blogpost-title">{blogPost.title}</h2></a>
                <p className='blogpost-date'>{formattedDate} by {blogPost.author}</p>
                <p className="blog-blurb">{toPlainText(blogPost.body)}</p>
                <a href={`/blog/${blogPost.slug}`} className='blog-link-text'>Read More</a>
              </div>
            </div>
          )}
        )}
      </div>
      <section className="page-content-wrapper">
        </section>
        <Footer />
    </div>
  )
}