import React, { useMemo, useEffect } from 'react';
import gsap from "gsap";
import ReactPlayer from 'react-player'

// Images
import headset from 'img/headset-compressed.png'

// Video
import waves from 'img/waves.mp4'

// Style
import './index.scss';

export function CTA() {

  return (
    <div className={`cta`}>
      <div className='cta-content'>
        <a href='https://shop.galea.co' className='cta-button '>Reserve Now</a>
      </div>
    </div>
  );
}