import React, { useEffect, useRef } from "react";
import { useScroll } from "react-use";
import gsap from "gsap";

// Sections
import { Header } from "components/Header";

// Style
import "./index.scss";

export function Inquire() {
	document.title = `Inquire about Galea`;

	const ref = useRef(null);
	// scroll position tracking
	const { y } = useScroll(ref);

	useEffect(() => {
		gsap.set(".header", {
			opacity: 1,
		});
	}, []);

	return (
		<div className={`inquire`} ref={ref}>
			<div className="inquire-container">
				<iframe
					title="Galea Signup Form"
					src="https://form.jotform.com/241574264257258"
					width="640"
					height="100vh"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
					loading="lazy"
				>
					Loading…
				</iframe>
			</div>
		</div>
	);
}
