import React, { useState, createRef, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom'
import Slider from "react-slick";

import { PhotoOverlay } from './PhotoOverlay'

// Style
import './index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function youtube_parser(url){
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length===11)? match[7] : false;
}

export const Carousel = forwardRef(({title, slides}, ref) => {
  const [overlay, setOverlay] = useState({
                                          name: '',
                                          title: '',
                                          img: '',
                                          open: false,
                                        });

  let navigate = useNavigate();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: '80px',
    draggable: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px',
          draggable: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px',
          draggable: true
        }
      },
    ]
  };

  var handleClick = (alt, image) => {
    setOverlay({description: alt, img: image, open: true})
  };

  let firstClientX, clientX;

  const preventTouch = e => {
    const minValue = 1; // threshold
  
    clientX = e.touches[0].clientX - firstClientX;
  
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
  
      return false;
    }
  };
  
  const touchStart = e => {
    firstClientX = e.touches[0].clientX;
  };
  
    let containerRef = createRef();
  
    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.addEventListener("touchstart", touchStart);
        containerRef.current.addEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
  
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("touchstart", touchStart);
          containerRef.current.removeEventListener("touchmove", preventTouch, {
            passive: false
          });
        }
      };
    });

  return (
    <>
      {overlay.open && <PhotoOverlay name={overlay.name}
                                     position={overlay.position}
                                     img={overlay.img}
                                     bio={overlay.bio}
                                     closeButtonAction={() => {setOverlay({open: false}); navigate('/#gallery')}} 
                        />}
    <section className={`carousel-container`} ref={ref}>
      <div className="callout callout-title">
        <h3>{title}</h3>
      </div>
      {slides && 
        <div style={{width: '100%'}} ref={containerRef} >
          <Slider className='carousel' 
                          {...settings} >
          {slides.map(({image, alt, url, _type}, i)=>
            _type === 'slide' ? 
            <div 
            className={`carousel-slide`}
            key={i}
            >
                  <img src={image} alt={alt} onClick={() => {handleClick(alt, image)}}/>
                </div>
              :
              <a href={url ? url : ''} 
              className={`carousel-slide carousel-slide-video`}
              key={i}
              target="_blank"
              rel='noreferrer'
              >
                  <img src={`https://img.youtube.com/vi/${youtube_parser(url)}/0.jpg`} alt={alt} />
                </a>
          )}
          </Slider>
        </div>
      }
    </section>
    </>
  );
})