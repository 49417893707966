import React from 'react'

// Components

// Style
import './index.scss'


// A single block to account for all custom components
export const BlockImage = ({ value }) => {
  return (
    <div className={`blockImage-contianer blockImage-container-${value.type}`}>
      <img src={value.image} className={`blockImage`} alt={value.alt} />
      {value.caption && <p className='sanity-caption'>{value.caption}</p>}
    </div>
  )
}