import React, { useMemo, useRef, useState, useEffect, useLayoutEffect } from 'react';
import gsap from "gsap";
import { useLocation } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// Utils
import { getData } from 'utils/sanity/'

// Queries
import { homepageQuery } from "utils/sanity/sanity-queries"

// Sections
import { Hero }  from 'pages/Home/Hero';
import { Tracker } from './Tracker';
import { LookInside } from './LookInside';
import { Features } from './Features';
import { Specs } from './Specs';
import { Carousel } from './Carousel';
import { Footer } from 'components/Footer';

// Style
import './index.scss'
import { Announcement } from './Announcement';

if (typeof window !== `undefined`) {
  gsap.registerPlugin( ScrollTrigger, ScrollToPlugin)
  gsap.core.globals( "ScrollTrigger", ScrollTrigger, "ScrollToPlugin", ScrollToPlugin)
}

export function Home() {
  document.title = `Galea`
  // Get the slug
  let location = useLocation();
  
  const [loaded, setLoaded] = useState(false),
        [sanityLoaded, setSanityLoaded] = useState(),
        [data, setData] = useState([]);

  const homeRef = useRef(null),
        splashRef = useRef(null),
        logoRef = useRef(null),
        exploreRef = useRef(null),
        featuresRef = useRef(null),
        galleryRef = useRef(null),
        specsRef = useRef(null),
        contactRef = useRef(null);

  // call the timeline using a memo
  var tl = useMemo(() => gsap.timeline(), [])

  useEffect(() => {
    const onPageLoad = () => {
      // setLoaded(true);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  useLayoutEffect(() => { 
    gsap.set(".header", {
      opacity: 0
    })
    document.body.style.overflowY = "hidden";
    document.body.style.height = "100vh";
  },[])

  useEffect(() => {
    tl.from("#outer-group", {
      duration: 2, 
      rotation: 90,
      transformOrigin: '50% 50%',
      ease: 'power4.inout',
    })
    .from(".outer-dot", {
      duration: .5, 
      opacity: 0,
      ease: 'power4.inout',
      stagger: .25
    }, 0)
    .from("#inner-group", {
      duration: 2, 
      rotation: -90,
      transformOrigin: '50% 50%',
      ease: 'power4.inout',
    }, 2)
    .from(".inner-dot", {
      duration: .5, 
      opacity: 0,
      ease: 'power4.inout',
      stagger: .25
    }, 2)
    .to("#outer-group", {
      duration: 2, 
      rotation: 90,
      transformOrigin: '50% 50%',
      ease: 'power4.inout',
    }, 2)
    .from("#center-dot", {
      duration: .5,
      opacity: 0,
      ease: 'power4.inout',
    }, 4.5) 
    .to("#center-dot", {
      duration: .5,
      boxShadow:"50px 50px 5px #fff",
      ease: 'power4.inout',
    }, 4.5) 
    // Fade out the splash content
    .to('.splash-content', {duration: 1, opacity: 0}, 6)
    // Make the splash layer fade out
    .to(splashRef.current, {
      duration: 2.5,
      opacity: 0
    }, 6)
    .to(".header", {
      opacity: 1,
      duration: 2.5
    }, 6)
        .call(() => {
          document.body.style.overflowY = "hidden";
          document.body.style.height = "unset";
        })
    .call(() => setLoaded(true))
    .call(() => !location.hash && window.history.replaceState(null, "Galea", "/#home"))
    // If there's a hash location, skip to end of timeline
    if (location.hash) {
      tl.progress(1, false);
    }
  }, [tl, location.hash]);

  useLayoutEffect(() => {
    if(location.hash) {
      setLoaded(true)
      switch (location.hash) {
        case '#home':
          window.scrollTo({top: 0, behavior: 'smooth'})
          break;
        case '#explore':
          exploreRef.current && window.scrollTo({top: exploreRef.current.offsetTop - 80, behavior: 'smooth'})
          break;
        case '#features':
          featuresRef.current && window.scrollTo({top: featuresRef.current.offsetTop - 80, behavior: 'smooth'})
          break;
        case '#gallery':
          galleryRef.current && window.scrollTo({top: galleryRef.current.offsetTop - 80, behavior: 'smooth'})
          break;
        case '#specs':
          specsRef.current && window.scrollTo({top: specsRef.current.offsetTop  - 80, behavior: 'smooth'})
          break;
        case '#contact':
          contactRef.current && window.scrollTo({top: contactRef.current.offsetTop  - 80, behavior: 'smooth'})
          break;
        default:
          return;
      }
    } else {
    }
  }, [location.hash, exploreRef, featuresRef, specsRef, contactRef])


  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        const dataResponse = await getData(homepageQuery)
        setData(dataResponse)
        setSanityLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return (
    <div className={`home`} ref={homeRef} >
      <Hero homeRef={homeRef}/>
      {sanityLoaded && data.announcement.active && <Announcement info={data.announcement}/>}
      <LookInside ref={exploreRef} />
      <Features ref={featuresRef} homeRef={homeRef} loaded={loaded}/>
      {!sanityLoaded ? <></> : <Carousel title={data.slideshow.title} slides={data.slideshow.slides} ref={galleryRef}/>}
      <Specs ref={specsRef} />
      <Footer ref={contactRef} />
      {!loaded && 
      <div ref={splashRef} className='splash'>
        <div className='splash-content' >   
        <svg ref={logoRef} className="splash-logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 346.7 346.7" style={{enableBackground:"new 0 0 346.7 346.7"}} xmlSpace="preserve" fill="red">
        <g id="all-dots">
          <g id="outer-group" className="outer-group">
            <circle className="outer-dot" id="o-8" cx="72.5" cy="72.4" r="30.6"/>
            <circle className="outer-dot" id="o-7" cx="30.6" cy="173.3" r="30.6"/>
            <circle className="outer-dot" id="o-6" transform="matrix(0.4543 -0.8909 0.8909 0.4543 -204.7512 214.2017)" cx="72.5" cy="274.2" r="30.6"/>
            <circle className="outer-dot" id="o-5" cx="173.4" cy="316" r="30.6"/>
            <circle className="outer-dot" id="o-4" cx="274.3" cy="274.2" r="30.6"/>
            <circle className="outer-dot" id="o-3" cx="316.1" cy="173.3" r="30.6"/>
            <circle className="outer-dot" id="o-2" transform="matrix(0.4543 -0.8909 0.8909 0.4543 85.1752 283.8445)" cx="274.3" cy="72.4" r="30.6"/>
            <circle className="outer-dot" id="o-1" cx="173.4" cy="30.6" r="30.6"/>
          </g>
          <g id="inner-group" className="inner-group">
            <circle className="inner-dot" id="o-1_00000166635939716586994780000014941562266920850578_" cx="206.9" cy="92.2" r="23.5"/>
            <circle className="inner-dot" id="o-2_00000132809980073417012240000002900584029788712122_" transform="matrix(0.1602 -0.9871 0.9871 0.1602 75.8276 368.5447)" cx="254.5" cy="139.7" r="23.5"/>
            <circle className="inner-dot" id="o-3_00000051385827500856400170000007623578648922027184_" cx="254.5" cy="206.9" r="23.5"/>
            <circle className="inner-dot" id="o-4_00000066504285540154904730000005777999934469806214_" cx="207" cy="254.5" r="23.5"/>
            <circle className="inner-dot" id="o-5_00000087398572431037179350000017215415387257056147_" cx="139.7" cy="254.5" r="23.5"/>
            <circle className="inner-dot" id="o-6_00000018924471218330304510000007694410162431970703_" cx="92.2" cy="206.9" r="23.5"/>
            <circle className="inner-dot" id="o-7_00000102516181505395058920000010038525310669068477_" cx="92.2" cy="139.7" r="23.5"/>
            <circle className="inner-dot" id="o-8_00000049904715107682742360000006149439328168013221_" cx="139.7" cy="92.2" r="23.5"/>
          </g>
          <path id="center-dot" fill="red" className="center-dot" d="M226,169.3C226,169.2,226,169.2,226,169.3c-0.2-0.4-0.4-0.7-0.5-1c-0.1-0.3-0.2-0.5-0.4-0.8
            c0,0,0,0,0,0l0,0c-0.1-0.1-0.2-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1c-0.2-0.3-0.5-0.7-0.7-1c-0.1-0.1-0.2-0.2-0.3-0.3
            c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1
            c-0.8-0.8-1.7-1.4-2.7-1.9c-0.3-0.1-0.6-0.3-1-0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0
            c-0.4-0.1-0.9-0.2-1.3-0.3c-0.1,0-0.1,0-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c0,0,0,0,0,0c-0.8,0-1.5-0.2-2.2-0.5c0,0-0.1,0-0.1,0
            c-0.7-0.3-1.3-0.7-1.9-1.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.4-0.5-0.7-1-1-1.6c-0.2-0.6-0.4-1.2-0.4-1.8c0-0.1,0-0.2,0-0.3
            c0-0.5,0-1,0.1-1.5c0-0.1,0-0.2,0.1-0.3c0.3-1.2,0.9-2.3,1.7-3.2c0.4-0.6,0.8-1.2,1.1-1.8c0-0.1,0.1-0.1,0.1-0.2
            c0.3-0.6,0.5-1.1,0.7-1.7c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.1c0.3-0.9,0.4-1.8,0.4-2.7c0-0.3,0-0.6,0-0.8c0-0.5-0.1-1-0.1-1.5
            c-0.1-0.8-0.3-1.5-0.6-2.2c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.5-0.5-1-0.8-1.4c0,0,0,0,0,0c-0.2-0.3-0.4-0.5-0.6-0.8
            c-0.3-0.3-0.5-0.7-0.8-1l0,0c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.5c-0.4-0.3-0.7-0.6-1.1-0.9
            c-0.3-0.2-0.7-0.4-1.1-0.6c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1,0c-0.3-0.1-0.7-0.2-1-0.3
            c-0.3-0.1-0.5-0.2-0.8-0.3c0,0,0,0,0,0s0,0,0,0c-0.1,0-0.3,0-0.4-0.1c0,0-0.1,0-0.1,0c-0.4-0.1-0.8-0.1-1.2-0.2c-0.1,0-0.3,0-0.4,0
            c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.5,0-1.1,0.1-1.6,0.1
            c-0.1,0-0.2,0-0.3,0.1c-0.4,0.1-0.7,0.2-1,0.3c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0
            c-0.4,0.1-0.7,0.4-1,0.5c-0.3,0.2-0.7,0.3-1,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c0,0,0,0,0,0c-1.8,1.8-4.6,2.4-7.1,1.4c-2.5-1-4-3.4-4-6
            c-0.6-4.9-4.3-8.8-9.1-9.8c-0.3-0.1-0.6-0.1-1-0.1c-0.4-0.1-0.8-0.2-1.3-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
            c-0.7,0-1.4,0.1-2.1,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2,0.1-0.4,0.1-0.6,0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
            c-0.3,0.1-0.6,0.3-0.9,0.5c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0.2-0.7,0.5-1,0.7
            c-0.3,0.2-0.5,0.4-0.7,0.7c-0.7,0.6-1.2,1.4-1.7,2.2c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
            c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c-0.1,0.3-0.2,0.7-0.3,1.1c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0,0,0
            c0,2.5-1.5,4.9-4,5.9c-2.5,1-5.3,0.4-7.1-1.4c-3.9-3-9.3-3.2-13.4-0.5c-0.3,0.2-0.5,0.4-0.8,0.6c-0.3,0.3-0.7,0.5-1,0.8
            c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.5,0.5-0.9,1.1-1.3,1.7c-0.2,0.3-0.3,0.5-0.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6
            c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.1,0.4-0.1,0.6
            c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.4c0,0,0,0,0,0c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.1,0.8-0.2,1.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2
            c0,0.9,0,1.9,0.2,2.8c0.1,0.8,0.3,1.6,0.6,2.3c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.4,0.6,0.6,0.8
            c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.3,0.6,0.5,0.8c1.8,1.8,2.4,4.6,1.4,7.1c-1,2.5-3.4,4-6,4c0,0,0,0,0,0c-0.8,0.1-1.6,0.3-2.4,0.6
            c-3.3,1.1-5.9,3.7-7.1,6.9c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.5-0.2,0.8
            c-0.1,0.7-0.2,1.4-0.2,2.2c0,0.2,0.1,0.3,0.1,0.5c0,0.7,0.1,1.4,0.3,2.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3
            c0,0.1,0.1,0.2,0.2,0.4c0,0,0,0,0,0.1c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.3,0.6,0.5,0.9c0.1,0.2,0.2,0.4,0.3,0.6
            c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.6,0.8,0.9,1.2c0.1,0.1,0.1,0.1,0.2,0.2
            c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1l0,0c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0.5,0.5,1,1,1.7,1.4
            c0.2,0.2,0.5,0.3,0.7,0.4c0.4,0.2,0.8,0.5,1.2,0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0.3,0.1,0.5,0.2,0.8,0.3
            c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.3,0.1,0.6,0.2,1,0.2c2.5,0,4.9,1.5,6,4c1,2.5,0.4,5.3-1.4,7.1
            l0,0c-0.5,0.7-0.9,1.4-1.3,2.1c-1,2.1-1.3,4.4-1,6.7c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.4c0,0,0,0.1,0,0.1
            c0.1,0.3,0.2,0.6,0.2,0.8c0.1,0.2,0.1,0.4,0.1,0.5c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3
            c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.3,0.4,0.5,0.6,0.8
            c0.1,0.1,0.1,0.2,0.2,0.2c0,0.1,0.1,0.1,0.1,0.1c0.2,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.4,0.3,0.6,0.5c0.4,0.3,0.7,0.6,1.1,0.9
            c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.3,0.7,0.4
            c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.2,0.8,0.4,1.3,0.5c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0.1,0.8,0.2,1.1,0.2c0.3,0,0.5,0,0.8,0.1
            c0.3,0,0.7,0.1,1,0c0.3,0,0.6-0.1,0.9-0.1c0.3,0,0.6-0.1,0.8-0.1c0.4-0.1,0.7-0.2,1-0.3c0.1,0,0.2-0.1,0.3-0.1
            c0.1,0,0.2-0.1,0.4-0.1c0.4-0.1,0.7-0.3,1-0.4c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0.1,0,0.1-0.1c0.6-0.3,1.1-0.7,1.7-1.1
            c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.3,0.8-0.6,1.2-0.9c0.1,0,0.1-0.1,0.2-0.1c0.5-0.2,1-0.4,1.5-0.6
            c0.1,0,0.1,0,0.2,0c1.2-0.2,2.4-0.1,3.6,0.4c0,0,0.1,0,0.1,0c0.5,0.2,1,0.5,1.4,0.9c0.1,0.1,0.3,0.2,0.4,0.4
            c0.3,0.3,0.6,0.6,0.8,0.9c0.1,0.1,0.2,0.2,0.3,0.4c0.6,1,1,2.2,1,3.4c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.2c0.1,0.8,0.3,1.5,0.6,2.2
            c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.1c0.2,0.5,0.4,1,0.7,1.5c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.6,0.8,1.2,1.2,1.7
            c0.2,0.2,0.3,0.4,0.5,0.6c0.6,0.6,1.3,1.2,2.1,1.7c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.5,0.3,0.8,0.4
            c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0.1
            c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0.4,0.1,0.7,0.1,1.1,0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0.1,0.7,0.1c0.3,0,0.5-0.1,0.8-0.1c0.5,0,0.9-0.1,1.4-0.1c0,0,0,0,0,0
            c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.5-0.1,0.8-0.2c0.1-0.1,0.3-0.1,0.4-0.2
            c0.4-0.2,0.8-0.3,1.2-0.6c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.7-0.4,1-0.6c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.5-0.4,0.7-0.7
            c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.3,0.4-0.6,0.5-0.9c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.3,0.3-0.7,0.4-1
            c0.1-0.2,0.2-0.4,0.2-0.6c0,0,0-0.1,0-0.1c0.1-0.4,0.2-0.8,0.2-1.2c0.1-0.3,0.1-0.5,0.2-0.8c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.4
            c0-0.5,0.1-1,0.2-1.5c0-0.1,0.1-0.1,0.1-0.2c0.2-0.5,0.4-1,0.7-1.5c0-0.1,0.1-0.1,0.1-0.1c0.7-1,1.6-1.8,2.8-2.3c0,0,0.1,0,0.1,0
            c0.5-0.2,1.1-0.3,1.6-0.4c0.2,0,0.4,0,0.5,0c0.4,0,0.9,0,1.3,0.1c0.2,0,0.3,0,0.5,0.1c1.2,0.3,2.2,0.8,3.1,1.7l0,0
            c0.1,0,0.1,0.1,0.2,0.1c0.6,0.4,1.3,0.8,1.9,1.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0.5,0.2,1,0.4,1.5,0.6c0.1,0,0.2,0,0.3,0.1
            c0.7,0.2,1.4,0.3,2.1,0.4c0.3,0,0.5,0.1,0.8,0.1c0.9,0,1.9-0.1,2.8-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.6-0.2,1.2-0.4,1.8-0.6
            c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0,0,0.1-0.1,0.1-0.1
            c0.5-0.4,1-0.8,1.5-1.2c0,0,0.1-0.1,0.1-0.1c0.3-0.3,0.5-0.6,0.7-0.9c0.3-0.4,0.6-0.7,0.9-1.1c0,0,0,0,0-0.1
            c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.2c0.2-0.5,0.4-1,0.6-1.6c0.1-0.2,0.1-0.3,0.2-0.5
            c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0l0,0c0.2-0.8,0.3-1.6,0.3-2.4c0-0.2,0-0.4,0-0.7c0-0.6-0.1-1.1-0.2-1.7
            c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.2s0-0.2-0.1-0.2c-0.1-0.5-0.3-1-0.5-1.6c-0.1-0.2-0.2-0.5-0.3-0.7
            c0-0.1-0.1-0.1-0.1-0.1c-0.3-0.6-0.6-1.2-1-1.7c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c-0.5-0.6-0.9-1.2-1.2-1.9c0,0,0-0.1,0-0.1
            c-0.3-0.7-0.5-1.4-0.5-2.2c0-0.4-0.1-0.7,0-1.1c0.1-0.6,0.2-1.2,0.5-1.9c0.2-0.6,0.6-1.1,0.9-1.6c0.1-0.1,0.1-0.2,0.2-0.2
            c0.3-0.4,0.7-0.7,1.1-1c0.1-0.1,0.2-0.1,0.3-0.2c1-0.6,2.2-1,3.4-1c0.7-0.1,1.4-0.3,2.1-0.5c0.1,0,0.1-0.1,0.2-0.1
            c0.6-0.2,1.1-0.4,1.7-0.7c0,0,0.1,0,0.1-0.1s0.1-0.1,0.1-0.1c0.8-0.4,1.5-1,2.2-1.6c0.2-0.2,0.4-0.4,0.6-0.6
            c0.4-0.4,0.7-0.8,0.9-1.2c0.4-0.6,0.8-1.3,1.1-2c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.5,0.4-1,0.5-1.6c0,0,0,0,0,0c0.1-0.3,0.1-0.6,0.1-1
            c0.1-0.4,0.2-0.8,0.2-1.3v0c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.5-0.1-0.9-0.2-1.4
            c-0.1-0.4-0.2-0.8-0.3-1.1c0,0,0-0.1-0.1-0.1C226,169.5,226,169.4,226,169.3z"/>
        </g>
        </svg>
        </div>     
      </div>}
    </div>
  );
}