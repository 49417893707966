import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

// DOM Packages
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { useMedia } from "react-use";

// Pages
import { Header } from "components/Header";
import { Home } from "pages/Home";
import { Inquire } from "pages/Inquire";
import { Newsletter } from "pages/Newsletter";
import { Blog } from "pages/Blog";
import { BlogPost } from "pages/BlogPost";
import { CaseStudy } from "pages/CaseStudy";
import { CaseStudies } from "pages/CaseStudies";

// Contexts
import { PageStateProvider } from "contexts/PageStateContext";
import { BlogProvider } from "contexts/BlogContext";

// Style
import "style/index.scss";
import { CaseStudiesProvider } from "contexts/CaseStudiesContext";

//Tag manager code
const tagManagerArgs = {
  gtmId: "GTM-5MNP6L3Q",
};
TagManager.initialize(tagManagerArgs);

function App() {
  const isWide = useMedia("(min-width: 480px)");

  //Tag manager pageview
  window.dataLayer.push({
    event: "pageview",
  });

  //Smooth scrolling polyfill for older browsers
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  const handleAcceptCookie = () => {
    // if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    TagManager.initialize(tagManagerArgs);
    // }
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <>
      <PageStateProvider>
        <BlogProvider>
          <CaseStudiesProvider>
            <Router>
              <Header />
              <Routes>
                <Route index element={<Home />} />
                <Route exact path="/inquire" element={<Inquire />} />
                <Route exact path="/newsletter" element={<Newsletter />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/caseStudies" element={<CaseStudies />} />
                <Route path="/caseStudies/:slug" element={<CaseStudy />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                disableStyles
                expires={150}
                contentClasses="cookie-consent-text"
                buttonWrapperClasses="cookie-consent-buttons"
                declineButtonClasses="cookie-consent-decline"
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
              >
                {isWide ? (
                  <span>
                    OpenBCI uses{" "}
                    <a
                      href="https://docs.openbci.com/FAQ/Cookie/"
                      className="cookie-link"
                    >
                      cookies
                    </a>
                    . We use them for advertising, analytics, and maintaining a
                    well-functioning website.
                  </span>
                ) : (
                  <span>
                    OpenBCI uses{" "}
                    <a
                      href="https://docs.openbci.com/FAQ/Cookie/"
                      className="cookie-link"
                    >
                      cookies
                    </a>
                    .
                  </span>
                )}{" "}
              </CookieConsent>
            </Router>
          </CaseStudiesProvider>
        </BlogProvider>
      </PageStateProvider>
    </>
  );
}

const container = document.getElementById("root");

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render
window.onload = () => {
  root.render(<App />);
};
