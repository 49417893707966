import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Images
import logo from 'img/galea-logo.svg'

import './index.scss'

export function Header() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`header`}>
        <Link className="header-logo-container" to='/#home'>
          <img id='logo' className='logo' src={logo} alt='galea-logo' width='154' height='30' />
        </Link>
        <svg className={`hamburger ${isActive ? "cross" : ''}`} onClick={() => setIsActive(!isActive)} viewBox="0 0 800 600">
          <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
          <path d="M300,320 L540,320" id="middle"></path>
          <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
        </svg>
        <nav className='desktop-links'>
          <Link className='header-link' to='/#features' ><span>/</span> Features</Link>
          <Link className='header-link' to='/#gallery' ><span>/</span> Gallery</Link>
          <Link className='header-link' to='/#specs' ><span>/</span> Specs</Link>
          <Link className='header-link' to='/blog' ><span>/</span> Blog</Link>
          {/*<Link className='header-link' to='/casestudies' ><span>/</span> Case Studies</Link>*/}
          <a className='header-button header-button-secondary' href='https://portal.galea.co/' >Login</a> 
          <Link className='header-button' onClick={() => setIsActive(false)} to='/inquire' >Request A Quote</Link>        
        </nav>
        <nav className={`mobile-nav ${isActive ? "isActive" : ''}`}>
          <Link className='header-link' to='/#features' onClick={() => setIsActive(false)}><span>/</span> Features</Link>
          <Link className='header-link' to='/#gallery' onClick={() => setIsActive(false)}><span>/</span> Gallery</Link>
          <Link className='header-link' to='/#specs' onClick={() => setIsActive(false)}><span>/</span> Specs</Link>
          <Link className='header-link' to='/blog' onClick={() => setIsActive(false)}><span>/</span> Blog</Link>
          {/*<Link className='header-link' to='/casestudies' onClick={() => setIsActive(false)}><span>/</span> Case Studies</Link>*/}
          <a className='header-button header-button-secondary' href='https://portal.galea.co/' >Login</a>        
          <Link className='header-button' onClick={() => setIsActive(false)} to='/inquire' >Request A Quote</Link>        
        </nav>
      </div>
  );
}