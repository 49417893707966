import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { ScrollToTop } from 'utils/ScrollToTop';
import { formatDate } from 'utils/blogFunctions'

import image from 'img/headset-compressed.png'

// Queries
import { BlogContext } from 'contexts/BlogContext';

// Sections
import { Loader } from 'components/Loader';
import { PageHero } from 'components/PageHero';
import { PageOverhang } from 'components/PageOverhang';

// Style
import './index.scss'
import { Footer } from 'components/Footer';

export const BlogPost = () => {
  const { blogPosts, loaded} = useContext(BlogContext);
  let { slug: urlSlug } = useParams()
  let navigate = useNavigate();

  
  const pageSlugs = blogPosts.map((blogPost) => { return blogPost.slug })

  const slugMatch = pageSlugs.includes(urlSlug)
    const blogPost = blogPosts.find(({ slug }) => {
      return slug === urlSlug })

  //check to see if the slug exists in our project, otherwise send us home
  useEffect(() => {
    if (loaded){
      if (slugMatch) {
        (document.title = `Galea | ${blogPost.title}`)
      } else {
        navigate("/")
      }
    }
  }, [loaded, navigate, slugMatch, blogPost])

  const formattedDate = blogPost && formatDate(blogPost.creationDate)


  return ( !loaded ?
    <Loader /> 
    :
    <div className={`blog-page`} >
      <ScrollToTop />
      <section className="page-content-wrapper">
          <PageOverhang 
            title={blogPost.title}
            author={blogPost.author}
            date={formattedDate}
            body={blogPost.body}
          />
        </section>
        <Footer />
    </div>
  )
}