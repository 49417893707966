import React, { useContext } from 'react';
import { ScrollToTop } from 'utils/ScrollToTop';
import {toPlainText} from '@portabletext/react'
import { formatDate } from 'utils/blogFunctions'; 

// Sections
import { Loader } from 'components/Loader';

// Style
import './index.scss'
import { CaseStudiesContext } from 'contexts/CaseStudiesContext';
import { Footer } from 'components/Footer';

export const CaseStudies = () => {
  const {caseStudies, loaded} = useContext(CaseStudiesContext);

  return ( !loaded ?
    <Loader /> 
    :
    <div className={`caseStudies-page`} >
      <ScrollToTop />
      <div className='caseStudies-list'>
        <h1 className='caseStudies-list-header'>Case Studies</h1>
        {caseStudies?.map((caseStudy,i) => {
          const formattedDate = formatDate(caseStudy.creationDate)

          return(
            <div className="caseStudies-link" key={i}>
              <a href={`/casestudies/${caseStudy.slug}`}><img src={caseStudy.image} className="caseStudies-thumb" alt="caseStudies-thumb"></img></a>
              <div className='caseStudy-description'>
                <a href={`/casestudies/${caseStudy.slug}`}><h2 className="caseStudy-title">{caseStudy.title}</h2></a>
                <p className='caseStudy-date'>{formattedDate} by {caseStudy.author}</p>
                <p className="caseStudies-blurb">{toPlainText(caseStudy.body)}</p>
                <a href={`/blog/${caseStudy.slug}`} className='caseStudies-link-text'>Read More</a>
              </div>
            </div>
          )}
        )}
      </div>
      <section className="page-content-wrapper">
        </section>
        <Footer />
    </div>
  )
}