import { BodyContent } from 'components/Sanity';
import React from 'react';

// Style
import './index.scss'

export const PageOverhang = ({title, body, author, date, children}) => {

  return (
    <>
    <section className="page-overhang">
      <div className='overhang-flex-container'>
        <div className="overhang-primary-content">
          <div className='overhang-title-measure'>
            <h1 className='overhang-title'>{title}</h1>
            {date && <h5>By {author} | {date}</h5>}
            <div className="rule-orange" />
          </div>
          <BodyContent blocks={body} />
        </div>
      </div>
    {children && 
      <div className='overhang-additional-content overhang-primary-content'>
        {children}
      </div>
    }
    </section>
    </>
  )
}