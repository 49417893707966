import React from 'react'
import appConfig from 'app.config'
import { PortableText } from '@portabletext/react'
 
// Components
import { Button } from './BlockButton'
import { BlockImage } from './BlockImage'
import { BlockRule } from './BlockRule'
import {Youtube } from './Youtube'
import { MuxVideo } from './MuxVideo'


// Style
import './index.scss'

    
export const BodyContent = ({ className, blocks, ...props }) => {
  return(
    <div className={className}>
      <PortableText
        value={blocks}
        projectId={appConfig.sanity.id}
        dataset={appConfig.sanity.dataset}
        components={{ 
          marks: {
            link: ({value, children}) => {
              // Read https://css-tricks.com/use-target_blank/
              const { blank, href } = value
              return blank ?
                <a href={href} target="_blank" rel="noreferrer">{children}</a>
                : <a href={href}>{children}</a>
            }
          },
          types: { 
            button: Button, 
            blockImage: BlockImage,
            blockRule: BlockRule,
            youtube: Youtube,
            muxVideo: MuxVideo
          }
        }}
        {...props}
      />
    </div>
  )
}