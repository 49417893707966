const getBlockContent = fieldName => `${fieldName}[]{
  ...,
  ...select(
    _type == "blockImage" => {
      "image": image.asset -> url,
      caption
    }
  ),
  ...select(
    _type == "oneOff" => {
      ...,
      options
    }
  ),
  ...select(
    _type == "twoPicture" => {
      "image1": image1.asset -> url,
      "image2": image2.asset -> url
    }
  ),
  ...select(
    _type == "fullWidthImage" => {
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "fullGridImage" => {
      ...,
      "image": imagex.asset -> url,
      "backgroundColor": backgroundColor.hex
    }
  ),
  ...select(
    _type == "headerAndBody" => {
      ...,
      "backgroundColor": backgroundColor.hex
    }
  ),
  ...select(
    _type == "pullquote" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "thinGridImage" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "tileArray" => {
      ...,
      tileArray[]{
        ...,
        "image": image.asset -> url
      }
    }
  ),
  ...select(
    _type == "cropFullWidthImage" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "logoBlock" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "muxVideo" => {
      ...,
      "playbackId": video.asset -> playbackId
    }
  ),
}`

export const homepageQuery = `*[_type=="homepage"][0] | {
  announcement,
  slideshow{title, slides[]{..., "image": image.asset -> url,}}
}`

export const blogQuery = `*[_type=="blogPost"] | order(creationDate desc) {
  title,
  creationDate,
  postDate,
  author,
  "slug": slug.current,
  "body": ${getBlockContent('body')},
  "image": image.asset -> url
}`

export const caseStudiesQuery = `*[_type=="caseStudy"] | order(creationDate desc) {
  title,
  creationDate,
  postDate,
  author,
  "slug": slug.current,
  "body": ${getBlockContent('body')},
  "image": image.asset -> url
}`