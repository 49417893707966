import React, { useState, useEffect } from 'react';
import { getData } from 'utils/sanity/'

// Queries
import { blogQuery } from "utils/sanity/sanity-queries"

const BlogContext = React.createContext({});

const BlogProvider = (props) => {
  // Set two loading states for without and with data
  const [loaded, setLoaded] = useState(false);
  // Set a project state
  const [blogPosts, setBlogPosts] = useState([]);


  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        // Get Case Studies
        const blogResponse = await getData(blogQuery)
        setBlogPosts(blogResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return (
    <BlogContext.Provider value={{ loaded, blogPosts }}>
      {props.children}
    </BlogContext.Provider>
  );
}

export { BlogContext, BlogProvider };