import React, { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnnotationLabel } from "react-annotation";
import { useMeasure } from "react-use";
import { isChrome } from "react-device-detect";

// Components
import { Callout } from "components/Callout";

// Style
import "./index.scss";

export const LookInside = forwardRef((props, ref) => {
	const header = <>A look inside Galea™</>,
		body = (
			<>
				Whether your team is exploring the future of immersive entertainment or
				on the verge of discovering a scientific breakthrough, Galea combined
				with the{" "}
				<a
					className="varjo-link"
					href="https://varjo.com/products/aero/"
					target="_blank"
					rel="noreferrer"
				>
					Varjo&nbsp;Aero
				</a>{" "}
				or{" "}
				<a
					className="varjo-link"
					href="https://varjo.com/products/xr-3/"
					target="_blank"
					rel="noreferrer"
				>
					XR-3
				</a>{" "}
				will get you the tightly time-locked data you need.
			</>
		);
	const [pinsRef, { width }] = useMeasure(),
		smallScreen = width < 842;

	useEffect(() => {
		document.getElementById("pins").style.overflow = "visible";
	}, [width]);

	return (
		<section className="look-inside" ref={ref}>
			<Callout header={header} body={body} />
			<div className="skeleton-wrapper">
				<div className="skeleton-image-wrapper">
					<figure
						height="671px"
						width="800px"
						className="skeleton-image"
						data-progressive-image-loaded
					/>
				</div>
				<div
					className="skeleton-pin-wrapper"
					ref={pinsRef}
					options={{ max: 3, scale: 1, speed: 300 }}
				>
					<svg
						height="671px"
						width="800px"
						className="pin-container-svg"
						id="pins"
					>
						<AnnotationLabel
							x={width * 0.29}
							y={width * 0.16}
							dy={smallScreen ? width * -0.12 : width * -0.113}
							dx={smallScreen ? width * -0.125 : width * -0.15}
							color={"#fff"}
							className="show-bg"
							editMode={false}
							connector={{
								type: "elbow",
							}}
							note={{
								title: "",
								label: "Battery powered\nWi-Fi based data transfer",
								align: "middle",
								orientation: `${smallScreen ? "topBottom" : "leftRight"}`,
								bgPadding: 20,
								padding: 15,
								wrapSplitter: "\n",
								titleColor: "#fff",
							}}
						/>
						<AnnotationLabel
							x={width * 0.68}
							y={width * 0.32}
							dy={smallScreen ? width * -0.28 : width * -0.273}
							dx={smallScreen ? width * 0.15 : width * 0.3}
							color={"#fff"}
							className="show-bg"
							editMode={false}
							connector={{
								type: "elbow",
							}}
							note={{
								title: "",
								label: "10 channels of dry,\nactive, EEG electrodes",
								align: "middle",
								orientation: `${smallScreen ? "topBottom" : "leftRight"}`,
								bgPadding: 20,
								padding: 15,
								wrap: 175,
								titleColor: "#fff",
							}}
						/>
						<AnnotationLabel
							x={smallScreen ? width * 0.2 : width * 0.25}
							y={smallScreen ? width * 0.669 : width * 0.669}
							dy={smallScreen ? width * 0.1 + 56 : width * 0.113}
							dx={smallScreen ? width * 0.2 : width * 0.15}
							color={"#fff"}
							className="show-bg"
							editMode={false}
							connector={{
								type: "elbow",
							}}
							note={{
								title: "",
								label:
									"EOG – 2 channels\nEXG – 2 channels\nFacial EMG – 4 channels\nPPG – Ear Clip sensor\nEDA – Forehead sensor\nEye Tracking – Varjo",
								align: "middle",
								orientation: "leftRight",
								bgPadding: 0,
								padding: 15,
								wrapSplitter: "\n",
								titleColor: "#fff",
							}}
						/>
					</svg>
				</div>
			</div>
		</section>
	);
});
