import React from 'react';

// Style
import './index.scss';

export function Announcement({info}) {
  return (
    <section className={`announcement-container`}>
        <a href={(info.url)} target="_blank" rel="noreferrer">{info.text}</a>
    </section>
  );
}
