import { format, parseISO, isAfter } from 'date-fns'

export const showIfCurrent = blogPosts => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() + 1)
  const currentBlogPosts = blogPosts.filter((item) => {
    const postDate = item.postDate ? parseISO(item.postDate) : yesterday
      // If it doesn't have a date, set it to today.
    return isAfter(today, postDate)
})
    return currentBlogPosts
  }

  export const formatDate = date => {
    const parsedDate = parseISO(date)
    const formattedDate = format(parsedDate, 'PP')
    return formattedDate
  }